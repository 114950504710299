import React, { useContext, useState, useEffect } from 'react'
import {message, Modal} from 'antd'
import { TailSpin } from 'react-loader-spinner';
import { AppState } from '../App';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ethers } from 'ethers';
import Marquee from 'react-fast-marquee';

const Register = () => {
    const useAppState = useContext(AppState);
    const [searchParams] = useSearchParams();
    const [isModalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [referrer, setReferrer] = useState("");
    const [amtInPol, setAmtInPol] = useState(0);

    useEffect(() => {
        async function getData() {
            let contract = await useAppState.getContract();
            let user = await contract.userInfo(useAppState.walletAddress);
            if(user.referrer == "0x0000000000000000000000000000000000000000") {
                setModalOpen(true);
            } else {
                setModalOpen(false);
            }
        }
        getData();
    },[useAppState.walletAddress, useAppState.change]);

    useEffect(() => {
        let ref = searchParams.get("ref");
        if(ref !== null) {
          setReferrer(ref)
        } else {
          setReferrer(useAppState.contractAddress);
        }
    }, []);

    let OkText = loading ? <TailSpin height={14} color='white' /> : <p>Register</p>;

    const register = async () => {
        setLoading(true);
        try {
            let usdtContract = await useAppState.getUsdtContract();
            if(useAppState.convert(await usdtContract.allowance(useAppState.walletAddress, useAppState.contractAddress)) < 10) {
                let _tx = await usdtContract.approve(useAppState.contractAddress, ethers.parseUnits("10000", "mwei"));
                await _tx.wait();
            }

            let contract = await useAppState.getContract();
            let tx = await contract.register(useAppState.walletAddress, referrer);
            await tx.wait();
            message.success("Sucessfully Registered");
            useAppState.setIsNewUser(true);
            useAppState.setChange(useAppState.change + 1);
        } catch (error) {
            if(error.reason) {
                message.error(error.reason);
            } else {
                message.error(error.message);
            }
        }
        setLoading(false);
    }

  return (
    <div>
        <Modal title="Register" open={isModalOpen} closable={false} onOk={register} okText={OkText} cancelButtonProps={{className:'hidden'}} >
            <div className='flex w-full flex-col justify-center items-center'>
                {/* <img src='register.gif' className='h-28' /> */}
                <p className='text-sm font-medium break-all bg-teal-200 border-2 border-teal-300 w-full p-1 rounded-sm'>Referrer: {referrer}</p>
                <p className='mt-2 text-sm font-medium break-all bg-teal-200 border-2 border-teal-300 w-full p-1 rounded-sm'>Free Registeration</p>

                <div className='h-60 mt-3 overflow-y-auto rounded-md w-full'>
                    {/* <h2 className='text-lg font-bold'><span className='text-pink-500'>100 FAI</span> Token Awaiting</h2> */}
                    <p className='font-semibold mt-1'>
                        "FAI tokens are 100% Decentralized blockchain-based tokens that power AI-driven crypto platforms, providing utility, governance, and incentives for users. <br />
                        Liquidity Mining – AI tokens can be used in DeFi liquidity pools, generating passive income."
                    </p>

                    {/* <h3 className='mt-4 text-lg'>Get <b className='text-pink-500'>100 FAI</b> tokens instant on registration</h3> */}

                    <h3 className='text-pink-500 mt-6 font-bold'>Fortune AI Agent Powered By</h3>
                    <Marquee className='mt-4'>
                      <div className='ml-28 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#cecaca" d="m17.82 16.342l5.692-3.287A.98.98 0 0 0 24 12.21V5.635a.98.98 0 0 0-.488-.846l-5.693-3.286a.98.98 0 0 0-.977 0L11.15 4.789a.98.98 0 0 0-.489.846v11.747L6.67 19.686l-3.992-2.304v-4.61l3.992-2.304l2.633 1.52V8.896L7.158 7.658a.98.98 0 0 0-.977 0L.488 10.945a.98.98 0 0 0-.488.846v6.573a.98.98 0 0 0 .488.847l5.693 3.286a.98.98 0 0 0 .977 0l5.692-3.286a.98.98 0 0 0 .489-.846V6.618l.072-.041l3.92-2.263l3.99 2.305v4.609l-3.99 2.304l-2.63-1.517v3.092l2.14 1.236a.98.98 0 0 0 .978 0z"></path></svg>
                        <h3 className='text-gray-300 font-bold text-2xl ml-3'>Polygon</h3>
                      </div>
                      <div className='ml-28 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 330 512"><path fill="#cecaca" d="M82.26 292.547L0 146.302L82.26 0h164.574L330 146.302H164.518zM330 365.697l-82.369-146.244l-82.26 146.245H0L83.056 512H247.63z"></path></svg>
                        <h3 className='text-gray-300 font-bold text-2xl ml-3'>Solidity</h3>
                      </div>
                      <div className='ml-28 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#cecaca" d="M22.783 24H9.317l2.196-3.69a5.23 5.23 0 0 1 4.494-2.558h6.775ZM1.217 0h21.566l-3.718 6.247H1.217ZM9.76 9.763a5.73 5.73 0 0 1 4.92-2.795h4.01L8.498 24h-7.26Z"></path></svg>
                        <h3 className='text-gray-300 font-bold text-2xl ml-3'>Openzeppelin</h3>
                      </div>
                      <div className='ml-28 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 1664 1664"><path fill="#cecaca" d="M1456 1216q0-40-28-68l-208-208q-28-28-68-28q-42 0-72 32q3 3 19 18.5t21.5 21.5t15 19t13 25.5t3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13t-19-15t-21.5-21.5t-18.5-19q-33 31-33 73q0 40 28 68l206 207q27 27 68 27q40 0 68-26l147-146q28-28 28-67M753 511q0-40-28-68L519 236q-28-28-68-28q-39 0-68 27L236 381q-28 28-28 67q0 40 28 68l208 208q27 27 68 27q42 0 72-31q-3-3-19-18.5T543.5 680t-15-19t-13-25.5T512 608q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13t19 15t21.5 21.5t18.5 19q33-31 33-73m895 705q0 120-85 203l-147 146q-83 83-203 83q-121 0-204-85l-206-207q-83-83-83-203q0-123 88-209l-88-88q-86 88-208 88q-120 0-204-84L100 652q-84-84-84-204t85-203L248 99q83-83 203-83q121 0 204 85l206 207q83 83 83 203q0 123-88 209l88 88q86-88 208-88q120 0 204 84l208 208q84 84 84 204"></path></svg>
                        <h3 className='text-gray-300 font-bold text-2xl ml-3'>PolygonScan</h3>
                      </div>
                      <div className='ml-28 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#cecaca" d="M20.083 6.972L19.5 5.056l-.583 1.916L17 7.556l1.917.583l.583 1.917l.583-1.917L22 7.556zM5.89 7.695c0-.228.172-.417.389-.417h3.11c.218 0 .39.189.39.417s-.172.416-.39.416h-3.11c-.217 0-.39-.189-.39-.416m7.222 0c0-.228.172-.417.389-.417h3.111c.217 0 .39.189.39.417s-.173.416-.39.416H13.5c-.217 0-.389-.189-.389-.416M7 9.767c0-.456.372-.822.833-.822s.834.366.834.822v2.8a.833.833 0 0 1-1.667 0zm7.222 0c0-.456.373-.822.834-.822s.833.366.833.822v2.8a.832.832 0 0 1-1.667 0zm-8.889 3.066a1.666 1.666 0 1 1-3.333 0a1.666 1.666 0 0 1 3.333 0m13.89 1.667a1.666 1.666 0 1 0 0-3.332a1.666 1.666 0 0 0 0 3.332m-4 2.411c.166.228.144.572-.056.761c-.5.467-1.867 1.273-3.823 1.273c-1.944 0-3.21-.806-3.65-1.306a.59.59 0 0 1 0-.761a.446.446 0 0 1 .678 0c.234.267 1.234.989 2.972.989c1.74 0 2.89-.723 3.2-1.017a.446.446 0 0 1 .678.061"></path></svg>
                        <h3 className='text-gray-300 font-bold text-2xl ml-3'>PinkLock</h3>
                      </div>
                      <div className='ml-28 flex items-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 32 32"><path fill="#cecaca" d="m15.927 23.959l-9.823-5.797l9.817 13.839l9.828-13.839l-9.828 5.797zM16.073 0L6.254 16.297l9.819 5.807l9.823-5.801z"></path></svg>
                        <h3 className='text-gray-300 font-bold text-2xl ml-3'>Ethereum</h3>
                      </div>
                    </Marquee>
                </div>
            </div>
        </Modal>
    </div>
  )
}

export default Register