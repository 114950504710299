import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';

const WithdrawalHistory = () => {
  const useAppState = useContext(AppState);
  const [data, setData] = useState([
    {
      sno: 0,
      amount: 0,
      withdrawnAmt: 0,
      withdrawnAmtInFAI: 0,
      Autoupgrade: 0,
      uvsdtDebt: 0,
      price: 0,
      time: 0,
    },
  ]);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _team = await contract.getWithdrawalHistory(useAppState.walletAddress);
      setData([]);
      let arr = [];
      for(let i=_team.length - 1; i>=0; i--) {
        arr.push({
          sno: i+1,
          amount: useAppState.convert(_team[i].amount),
          withdrawnAmt: useAppState.convert(_team[i].withdrawnAmt),
          withdrawnAmtInFAI: useAppState.convert(_team[i].withdrawnAmtInFAI),
          Autoupgrade: useAppState.convert(_team[i].Autoupgrade),
          uvsdtDebt: useAppState.convert(_team[i].uvsdtDebt),
          time: Number(_team[i].time) * 1000,
        })
      }
      setData(arr);
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change])

  return (
    <div className='min-h-screen mt-6 w-full'>
      <div className="w-full flex justify-center">
        <div className="w-full">
            <div className="overflow-x-auto">
              <table className="table table-zebra table-md">
                {/* head */}
                <thead className='bg-blue-500 text-white'>
                  <tr>
                    <th></th>
                    <th>Total Amount</th>
                    <th>Received</th>
                    <th>VUSDT Debt Paid</th>
                    <th>Autoupgrade</th>
                    <th>Time</th>
                    <th>At Price</th>
                  </tr>
                </thead>
                <tbody>
                {data.map((e, i) => {
                  return (
                  <tr className='whitespace-nowrap'>
                    <th>{e.sno}</th>
                    <td>${e.amount.toFixed(2)}</td>
                    <td className='text-teal-500'>${e.withdrawnAmt.toFixed(2)} USDT / {e.withdrawnAmtInFAI.toFixed(2)} FAI</td>
                    <td className='text-red-500'>${e.uvsdtDebt.toFixed(2)}</td>
                    <td className='text-lime-500'>${e.Autoupgrade.toFixed(2)}</td>
                    <td>{new Date(e.time).toLocaleString()}</td>
                    <td className='text-pink-500'>${(e.withdrawnAmt.toFixed(2)/e.withdrawnAmtInFAI.toFixed(2)).toFixed(4)}</td>
                  </tr>
                )})}
                </tbody>
              </table>
            </div>

        </div>
      </div>
    </div>
  )
}

export default WithdrawalHistory