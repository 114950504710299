import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../App";
import { DownOutlined, LeftOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { TailSpin } from "react-loader-spinner";

const Tree = () => {
  const useAppState = useContext(AppState);
  const [address, setAddress] = useState("");
  const [B1, setB1] = useState("0x0000000000000000000000000000000000000000");
  const [B2, setB2] = useState("0x0000000000000000000000000000000000000000");
  const [C1, setC1] = useState("0x0000000000000000000000000000000000000000");
  const [C2, setC2] = useState("0x0000000000000000000000000000000000000000");
  const [C3, setC3] = useState("0x0000000000000000000000000000000000000000");
  const [C4, setC4] = useState("0x0000000000000000000000000000000000000000");
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [user, setUser] = useState({
    address: "",
    id: "",
    directTeam: "",
    start: 0,
    referrer: "",
    isUnBlocked: false,
    totalTeam: 0,
    rank: ""
  });
  const zeroAddr = "0x0000000000000000000000000000000000000000";
  const [randId, setRandId] = useState("");

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      setAddress(useAppState.walletAddress);
      setRandId(useAppState.walletAddress);
      let _binary1 = await contract.getMatrixDirect(useAppState.walletAddress);
      setB1(_binary1[0]);
      setB2(_binary1[1]);

      let _binary2 = await contract.getMatrixDirect(_binary1[0]);
      setC1(_binary2[0]);
      setC2(_binary2[1]);

      let _binary3 = await contract.getMatrixDirect(_binary1[1]);
      setC3(_binary3[0]);
      setC4(_binary3[1]);
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change]);

  const fetchDetails = async (_addr) => {
    setLoading(true);
    let contract = await useAppState.getContract();
    try {
      if (_addr == address) {
        let _user = await contract.userInfo(_addr);
        let upline = await contract.matrixUpline(_addr);
        setAddress(upline);

        let _binary1 = await contract.getMatrixDirect(upline);
        setB1(_binary1[0]);
        setB2(_binary1[1]);

        let _binary2 = await contract.getMatrixDirect(_binary1[0]);
        setC1(_binary2[0]);
        setC2(_binary2[1]);

        let _binary3 = await contract.getMatrixDirect(_binary1[1]);
        setC3(_binary3[0]);
        setC4(_binary3[1]);

      } else {
        setAddress(_addr);
        let _binary1 = await contract.getMatrixDirect(_addr);
        setB1(_binary1[0]);
        setB2(_binary1[1]);

        let _binary2 = await contract.getMatrixDirect(_binary1[0]);
        setC1(_binary2[0]);
        setC2(_binary2[1]);

        let _binary3 = await contract.getMatrixDirect(_binary1[1]);
        setC3(_binary3[0]);
        setC4(_binary3[1]);
      }
    } catch (error) {
      message.error(error.reason);
    }
    setLoading(false);
  };

  const findUser = async (_addr) => {
    setLoading2(true);
    try {
      setModalOpen(true);
      let contract = await useAppState.getContract();
      let _user = await contract.userInfo(_addr);
      setUser({
        address: _user.addr,
        directTeam: Number(_user.directTeam),
        start: Number(_user.joiningTime),
        rank: useAppState.levels[Number(_user.slotsPurchased) - 1],
        referrer: _user.referrer,
        totalTeam: Number(_user.totalTeam)
      });
    } catch (error) {
      message.error(error.reason);
    }
    setLoading2(false);
  };

  const changeAddr = async () => {
    try {
        let contract = await useAppState.getContract();
        setLoading(true);
        let isFound = true;
        let upline = {upline: randId}
        for(let i=0; i<24; i++) {
          upline = {upline: await contract.matrixUpline(upline.upline)};
          if(upline.upline == useAppState.walletAddress) {
            isFound = false;
            break;
          }
        }
        if(isFound) {
          message.warning("Invalid Community ID")
          setRandId(address);
          setLoading(false);
          return;
        }
        await fetchDetails(randId);
        setAddress(randId);
        setLoading(false);
    } catch (error) {
      message.error(error.message);
    }
  }

  return (
    <>
        {loading ? (
        <div className="mt-14 w-full flex justify-center">
          <TailSpin height={32} />
        </div>
      ) : (
        <div className="flex flex-col items-center mt-2 w-full p-4 overflow-x-auto min-h-screen">
          <Modal
            title={null}
            open={isModalOpen}
            closable={true}
            onCancel={() => setModalOpen(false)}
            okButtonProps={{ className: "hidden" }}
            cancelButtonProps={{ className: "hidden" }}
            styles={{content: {background: "white", padding: 0, borderRadius: "14px",}}}
            wrapClassName="custom-modal"
          >
            {loading2 ? (
              <div className="py-12 px-8 bg-opacity-60 flex justify-center items-center h-full w-full">
              <TailSpin height={14} />
              </div>
            ) : (
              <div className="flex justify-start blue_blur p-5 bg-opacity-60 pt-10 overflow-x-auto items-center">
                <div className="flex flex-col justify-center items-start">
                  <p className="text-center w-full text-black whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Address:
                  </p>
                  <p className="text-center w-full text-black whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Rank:
                  </p>
                  <p className="text-center w-full text-black whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Activation Date:
                  </p>
                  <p className="text-center w-full text-black whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Referred By:
                  </p>
                  <p className="text-center w-full text-black whitespace-nowrap border-2 border-[rgba(124,240,89,0.16)] bg-[rgba(124,240,89,0.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    Community Size:
                  </p>
                </div>
                <div className="flex w-full ml-5 flex-col justify-center items-start">
                  <p className="w-full text-black whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    {user.address}
                  </p>
                  <p className="w-full text-black whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    {user.rank}
                  </p>
                  <p className="w-full text-black whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    {new Date(user.start * 1000).toLocaleString()}
                  </p>
                  <p className="w-full text-black whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    {user.referrer}
                  </p>
                  <p className="w-full text-black whitespace-nowrap border-2 border-[rgba(240,194,89,.16)] bg-[rgba(240,194,89,.50)] rounded-lg font-semibold p-1 mt-2 px-4">
                    {user.totalTeam}
                  </p>
                </div>
              </div>
            )}
          </Modal>

          <h1 className="text-lime-500 font-bold text-2xl">Community</h1>
          <div className="text-teal-500 font-bold flex justify-center items-center">
            <input maxLength={7} className="p-2 px-4 mt-2 bg-gray-700 bg-opacity-45 w-[300px]" value={randId} onChange={(e) => setRandId(e.target.value)} placeholder="Address" />
            <span
              onClick={changeAddr}
              className="py-2 mt-2 px-2 bg-green-500 h-full ml-2 text-white cursor-pointer p-2 rounded-sm text-sm font-medium"
            >
              Search
            </span>
          </div>

          <div className="flex mt-4 flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center cursor-pointer">
              <img
                onClick={() => findUser(address)}
                src="bnb.png"
                className="h-12 bg-white rounded-full p-1"
              />
              <h1
                onClick={() => findUser(address)}
                className="text-teal-500 font-semibold text-xs mt-1"
              >
                {address.slice(0,5)}...{address.slice(38)}
              </h1>
              {address != useAppState.walletAddress ? (
                <span
                  onClick={() => fetchDetails(address)}
                  className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                >
                  Back <LeftOutlined />
                </span>
              ) : null}
            </div>
            <div className="border-2 mt-1 w-0 border-blue-500 h-16"></div>
          </div>

          <div className="flex relative justify-between w-2/3 md:w-1/2 border-t-0 border-blue-500 rounded-xl">
            <div className="flex flex-col justify-center items-center">
              <div className="border-2 w-0 border-blue-500 h-16"></div>
              <div className="flex flex-col items-center cursor-pointer">
                {B1 == zeroAddr ? (
                  <>
                    <img src="restrict.png" className="h-14" />
                    <h1 className="text-red-500 font-semibold text-xs">
                      (None)
                    </h1>
                    <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                      Vacant
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      onClick={() => findUser(B1)}
                      src="bnb.png"
                      className="h-12 bg-white rounded-full p-1"
                    />
                    <h1
                      onClick={() => findUser(B1)}
                      className="text-teal-500 font-semibold text-xs mt-1"
                    >
                      {B1.slice(0,5)}...{B1.slice(39)}
                    </h1>
                    <span
                      onClick={() => fetchDetails(B1)}
                      className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                    >
                      Tree <DownOutlined />
                    </span>
                  </>
                )}
              </div>
              <div className="border-2 mt-1 w-0 border-blue-500 h-16"></div>
            </div>

            <div className="absolute top-0 right-[33px] left-[33px] border-t-4 border-blue-500"></div>

            <div className="flex flex-col justify-center items-center">
              <div className="border-2 w-0 border-blue-500 h-16"></div>
              <div className="flex flex-col items-center cursor-pointer">
                {B2 == zeroAddr ? (
                  <>
                    <img src="restrict.png" className="h-14" />
                    <h1 className="text-red-500 font-semibold text-xs">
                      (None)
                    </h1>
                    <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                      Vacant
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      onClick={() => findUser(B2)}
                      src="bnb.png"
                      className="h-12 bg-white rounded-full p-1"
                    />
                    <h1
                      onClick={() => findUser(B2)}
                      className="text-teal-500 font-semibold text-xs mt-1"
                    >
                      {B2.slice(0,5)}...{B2.slice(39)}
                    </h1>
                    <span
                      onClick={() => fetchDetails(B2)}
                      className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                    >
                      Tree <DownOutlined />
                    </span>
                  </>
                )}
              </div>
              <div className="border-2 mt-1 w-0 border-blue-500 h-16"></div>
            </div>
          </div>
          
          {/* Layer 2 */}
          <div className="w-[76%] border-t-4 border-blue-500"></div>
          
          <div className="flex justify-between w-full">

            <div className="flex flex-col justify-between w-1/4 items-center">
              <div className="flex flex-col items-center w-full cursor-pointer">
                <div className="border-2 w-0 border-blue-500 h-16"></div>
                <div className="flex flex-col items-center cursor-pointer">
                {C1 == zeroAddr ? (
                  <>
                    <img src="restrict.png" className="h-14" />
                    <h1 className="text-red-500 font-semibold text-xs">
                      (None)
                    </h1>
                    <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                      Vacant
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      onClick={() => findUser(C1)}
                      src="bnb.png"
                      className="h-12 bg-white rounded-full p-1"
                    />
                    <h1
                      onClick={() => findUser(C1)}
                      className="text-teal-500 font-semibold text-xs mt-1"
                    >
                      {C1.slice(0,5)}...{C1.slice(39)}
                    </h1>
                    <span
                      onClick={() => fetchDetails(C1)}
                      className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                    >
                      Tree <DownOutlined />
                    </span>
                  </>
                )}
              </div>
              </div>
            </div>

            <div className="flex flex-col justify-center w-1/4 items-center">
              <div className="flex flex-col items-center w-full cursor-pointer">
                <div className="border-2 w-0 border-blue-500 h-16"></div>
                <div className="flex flex-col items-center cursor-pointer">
                {C2 == zeroAddr ? (
                  <>
                    <img src="restrict.png" className="h-14" />
                    <h1 className="text-red-500 font-semibold text-xs">
                      (None)
                    </h1>
                    <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                      Vacant
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      onClick={() => findUser(C2)}
                      src="bnb.png"
                      className="h-12 bg-white rounded-full p-1"
                    />
                    <h1
                      onClick={() => findUser(C2)}
                      className="text-teal-500 font-semibold text-xs mt-1"
                    >
                      {C2.slice(0,5)}...{C2.slice(39)}
                    </h1>
                    <span
                      onClick={() => fetchDetails(C2)}
                      className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                    >
                      Tree <DownOutlined />
                    </span>
                  </>
                )}
              </div>
              </div>
            </div>

            <div className="flex flex-col justify-center w-1/4 items-center">
              <div className="flex flex-col items-center w-full cursor-pointer">
                <div className="border-2 w-0 border-blue-500 h-16"></div>
                <div className="flex flex-col items-center cursor-pointer">
                {C3 == zeroAddr ? (
                  <>
                    <img src="restrict.png" className="h-14" />
                    <h1 className="text-red-500 font-semibold text-xs">
                      (None)
                    </h1>
                    <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                      Vacant
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      onClick={() => findUser(C3)}
                      src="bnb.png"
                      className="h-12 bg-white rounded-full p-1"
                    />
                    <h1
                      onClick={() => findUser(C3)}
                      className="text-teal-500 font-semibold text-xs mt-1"
                    >
                      {C3.slice(0,5)}...{C3.slice(39)}
                    </h1>
                    <span
                      onClick={() => fetchDetails(C3)}
                      className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                    >
                      Tree <DownOutlined />
                    </span>
                  </>
                )}
              </div>
              </div>
            </div>

            <div className="flex flex-col justify-center w-1/4 items-center">
              <div className="flex flex-col items-center w-full cursor-pointer">
                <div className="border-2 w-0 border-blue-500 h-16"></div>
                <div className="flex flex-col items-center cursor-pointer">
                {C4 == zeroAddr ? (
                  <>
                    <img src="restrict.png" className="h-14" />
                    <h1 className="text-red-500 font-semibold text-xs">
                      (None)
                    </h1>
                    <span className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium">
                      Vacant
                    </span>
                  </>
                ) : (
                  <>
                    <img
                      onClick={() => findUser(C4)}
                      src="bnb.png"
                      className="h-12 bg-white rounded-full p-1"
                    />
                    <h1
                      onClick={() => findUser(C4)}
                      className="text-teal-500 font-semibold text-xs mt-1"
                    >
                      {C4.slice(0,5)}...{C4.slice(39)}
                    </h1>
                    <span
                      onClick={() => fetchDetails(C4)}
                      className="py-1 mt-2 px-2 bg-green-500 rounded-sm text-sm font-medium"
                    >
                      Tree <DownOutlined />
                    </span>
                  </>
                )}
              </div>
              </div>
            </div>

          </div>

        </div>
      )}
    </>
  );
};

export default Tree;