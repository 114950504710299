import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppState } from '../App';
import Slots from './Slots';
import CopyToClipboard from 'react-copy-to-clipboard';
import { message } from 'antd';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import { TailSpin } from 'react-loader-spinner';
import { ethers } from 'ethers';

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days
  } = useTimer({ expiryTimestamp});

  return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
}

const UserInfo = () => {
  const useAppState = useContext(AppState);
  const intervalRef = useRef();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0)
  const [counter, setCounter] = useState(0);
  const [time, setTime] = useState(0);
  const [user, setUser] = useState({
    totalTeam: 0,
    totalDeposit: 0,
    directTeam: 0,
    directBusiness: 0,
    totalBusiness: 0,
    limit: 0,
    income: 0,
    limitLeft: 0,
    revenue: 0,
    hold: 0,
    airdrop: 0,
    weeklyDirect: [0, 0],
    royaltyTime: 0,
    royalty: false,
    weekly: false,
    slots: 0,
    claimable: 0,
    lastClaimed: 0,
    referrer: "",
    matrixTeam: 0,
    autoUpgrade: 0,
    lostIncome: 0
  })
  const [show, setShow]  = useState(0);
  const [loading, setLoading] = useState(false);
  const [time2, setTime2] = useState();
  const [tokenPrice, setTokenPrice] = useState(0);
  const [isAirdropAvl, setIsAirdropAvl] = useState(false);
  const [faiBalInUsd, setFaiBalInUsd] = useState(0);
  const [airdropClaimedInUsd, setAirdropClaimedInUsd] = useState(0);
  const [faiVals, setFaiVals] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if(time > 0) {
        const sec = Math.floor(((Date.now() - time) / 1000) % 60);
        const min = Math.floor(((Date.now() - time) / 60000) % 60);
        const hr = Math.floor(((Date.now() - time) / 3600000) % 24);
        const days = Math.floor((Date.now() - time) / 86400000);
        setHours(hr);
        setDays(days);
        setMinutes(min);
        setSeconds(sec);
        setCounter(counter + 1);
      } else {
        setHours(0);
        setDays(0);
        setMinutes(0);
        setSeconds(0);
      }
    }, 1000)
  },[time, counter])

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if(Number(user.lastClaimed) > 0) {
        setTime2(Date.now() - Number(user.lastClaimed));
      } else {
        setTime2(0);
      }
    }, 10);
    return () => clearInterval(intervalRef.current);
  }, [user.lastClaimed]);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _user = await contract.userInfo(useAppState.walletAddress);
      let _incomeInfo = await contract.incomeInfo(useAppState.walletAddress)
      // let _income = await contract.getIncomeLimit(useAppState.walletAddress);
      let _wallet = await contract.getUserWalletInfo(useAppState.walletAddress);
      // let _weekly = await contract.getWeeklyProgress(useAppState.walletAddress);
      let _weekly = [Number(await contract.weeklyDirectTeam(useAppState.walletAddress, (await contract.curWeek()))), 5];
      let _isWeekly = await contract.isWeeklyAchieved(useAppState.walletAddress, await contract.curWeek());
      setTime(Number(_user.joiningTime) * 1000);
      setShow(false);
      setUser({
        lostIncome: useAppState.convert(_incomeInfo.lostIncome),
        totalTeam: Number(_user.totalTeam),
        slots: Number(_user.slotsPurchased),
        totalDeposit: useAppState.convert(_user.totalDeposit),
        directTeam: Number(_user.directTeam),
        directBusiness: useAppState.convert(_user.directBusiness),
        totalBusiness: useAppState.convert(_user.totalBusiness),
        // limit: useAppState.convert(_income[0]),
        income: useAppState.convert(_incomeInfo.totalIncome),
        // limitLeft: useAppState.convert(_income[2]),
        revenue: useAppState.convert(_wallet[0]),
        airdrop: useAppState.convert(_wallet[1]),
        weeklyDirect: [Number(_weekly[0]), Number(_weekly[1])],
        royaltyTime: 0,
        weekly: _isWeekly,
        claimable: useAppState.convert(await contract.getClaimableAirdrop(useAppState.walletAddress)),
        lastClaimed: Number(_incomeInfo.airdropLastClaimTime) * 1000,
        referrer: _user.referrer,
        matrixTeam: Number(await contract.matrixTeam(useAppState.walletAddress)),
        autoUpgrade: useAppState.convert(_user.autoUpgrade)
      })
      setShow(true); 
      setTokenPrice(useAppState.convert(await contract.getPriceFromUniswapV2FAI.staticCall(ethers.parseUnits("1", "mwei"))))
      setFaiBalInUsd(useAppState.convert(await contract.getPriceFromUniswapV2FAI.staticCall(ethers.parseUnits(useAppState?.bal3.toString(), "mwei"))))
      setAirdropClaimedInUsd(useAppState.convert(await contract.getPriceFromUniswapV2FAI.staticCall(_wallet[1])))
      setFaiVals(useAppState.convert(await contract.getPriceFromUniswapV2FAI.staticCall(ethers.parseUnits("100", "mwei"))))

      let _isPaid = await contract.isPaidForAirdrop(useAppState.walletAddress);
      setIsAirdropAvl(_isPaid);
    }
    getData();
  },[useAppState.walletAddress, useAppState.change, useAppState.bal3])

  const claim = async () => {
    setLoading(true);
    try {
      let contract = await useAppState.getContract();
      let tx = await contract.claimAidrop();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Claimed");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading(false);
  }

  return (
    <div className="min-h-screen mt-6 w-full">
      <div className="flex md:flex-row flex-col md:justify-between justify-center w-full md:items-start items-center">
        <div className="mockup-phone m-0">
          <div className="camera"></div>
          <div className="display">
            <div className="relative artboard artboard-demo phone-1 p-2">
              <img
                src="https://preview.redd.it/dark-mobile-wallpaper-v0-478hf5gsqncc1.png?width=784&format=png&auto=webp&s=181268b84ad2b70405d403b6dbaccce680a92b6b"
                alt="Team Icon"
                className="rounded-l-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
              />

              <svg className='absolute top-3 left-6' xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"><path fill="white" d="M12 21q-1.05 0-1.775-.725T9.5 18.5t.725-1.775T12 16t1.775.725t.725 1.775t-.725 1.775T12 21m-5.65-5.65l-2.1-2.15q1.475-1.475 3.463-2.337T12 10t4.288.875t3.462 2.375l-2.1 2.1q-1.1-1.1-2.55-1.725T12 13t-3.1.625t-2.55 1.725M2.1 11.1L0 9q2.3-2.35 5.375-3.675T12 4t6.625 1.325T24 9l-2.1 2.1q-1.925-1.925-4.462-3.012T12 7T6.563 8.088T2.1 11.1"></path></svg>
              <svg className='absolute right-6 top-3' xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 20 20"><g fill="white"><rect width={2} height={5} x={16} y={7.5} rx={0.5}></rect><path d="M4 7.5h2.5v5H4zm3.25 0h2.5v5h-2.5zm3.25 0H13v5h-2.5z"></path><path fillRule="evenodd" d="M14 5.5H3A1.5 1.5 0 0 0 1.5 7v6A1.5 1.5 0 0 0 3 14.5h11a1.5 1.5 0 0 0 1.5-1.5V7A1.5 1.5 0 0 0 14 5.5M2.5 7a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" clipRule="evenodd"></path></g></svg>

            <div className='z-10 mt-4 bg-gray-800 bg-opacity-50 rounded-md p-3 w-[95%] overflow-x-hidden'>
              <p className="pb-2 border-b border-gray-400 border-opacity-30 flex items-center justify-between text-gray-200 font-semibold">
                <div className='flex items-center'>
                <svg className='mr-3' xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 128 128"><path fill="#fff" d="M64 0c35.348 0 64 28.652 64 64s-28.652 64-64 64S0 99.348 0 64S28.652 0 64 0m0 0"/><path fill="#7950dd" d="M85.898 49.242a5.76 5.76 0 0 0-5.418 0l-12.214 7.223l-8.532 4.742l-12.214 7.227a5.76 5.76 0 0 1-5.418 0l-9.707-5.649a5.42 5.42 0 0 1-2.711-4.52V46.989a4.97 4.97 0 0 1 2.71-4.52l9.708-5.417a5.74 5.74 0 0 1 5.418 0l9.707 5.418a5.42 5.42 0 0 1 2.71 4.52v7.218l8.329-4.965v-6.996a4.96 4.96 0 0 0-2.664-4.52l-17.86-10.382a5.74 5.74 0 0 0-5.418 0L24.266 37.727a4.61 4.61 0 0 0-2.934 4.52v20.991a4.97 4.97 0 0 0 2.711 4.496l18.059 10.407a5.76 5.76 0 0 0 5.418 0l12.214-7l8.352-4.965l12.172-6.977a5.76 5.76 0 0 1 5.418 0l9.707 5.418a5.42 5.42 0 0 1 2.707 4.52v11.062a4.97 4.97 0 0 1-2.707 4.516l-9.707 5.64a5.74 5.74 0 0 1-5.418 0l-9.707-5.418a5.42 5.42 0 0 1-2.711-4.515v-7.25l-8.106 4.738v7.219a4.97 4.97 0 0 0 2.707 4.52L80.5 100.03a5.75 5.75 0 0 0 5.422 0l18.058-10.383a5.42 5.42 0 0 0 2.688-4.511v-21a4.96 4.96 0 0 0-2.711-4.516zm0 0"/></svg>
                  <span className='flex flex-col'>
                    <span className='font-normal text-sm'>POL</span>
                  </span>
                </div>
                <span className='text-gray-200'>{useAppState.bal.toFixed(4)}</span>
              </p>
              <p className="pb-2 border-b border-gray-400 border-opacity-30 mt-2 flex items-center justify-between text-gray-200 font-semibold">
                <div className='flex items-center'>
                  <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><circle cx={16} cy={16} r={16} fill="#26a17b"></circle><path fill="#fff" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042c-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658s2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061c1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658c0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118s3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116s-3.301-1.914-7.694-2.117"></path></g></svg>
                  <span className='flex flex-col'>
                    <span className='font-normal text-sm'>USDT</span>
                    
                  </span>
                </div>
                <span className='text-gray-200'>{parseInt(useAppState.bal2)}</span>
              </p>
              <p className="mt-1 flex items-center justify-between text-gray-200 font-semibold">
                <div className='flex items-center'>
                  <img src='logo.png' className='h-8 w-8 bg-white p-1 rounded-full mr-3' />
                  <span className='flex flex-col'>
                    <span className='font-normal text-sm'>FAI</span>
                    <span className='text-gray-300 text-xs'>${tokenPrice.toFixed(6)}</span>
                  </span>
                </div>
                <div className='flex flex-col items-start'>
                  <span className='text-gray-200'>{useAppState.bal3.toFixed(2)}</span>
                  <span className='text-xs'>${faiBalInUsd.toFixed(2)}</span>
                </div>
              </p>
            </div>

              {/* Limit */}
              <div className="flex w-[95%] justify-center mt-4">
                <div className="w-full">
                  <div className="flex justify-between items-center ml-0 w-full bg-gradient-to-r from-red-700 via-orange-500 to-yellow-400 bg-opacity-80 px-6 py-2 shadow-lg rounded-2xl font-semibold text-lg border border-red-800 backdrop-blur-md">
                    <div>
                      <p className="text-lg font-semibold text-white flex items-center">
                        <span className="mr-2 text-sm text-yellow-300">⚠️</span>
                        Total Lost Income
                      </p>
                      <p className="text-2xl font-extrabold text-yellow-200 mt-2 drop-shadow-lg">
                        {user.lostIncome} USDT
                      </p>
                      <p className="text-sm text-white mt-1 opacity-80">Act now to stop losing Income!</p>
                    </div>

                    <div className="flex items-center justify-center bg-white bg-opacity-20 p-3 rounded-full shadow-md">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 32 32">
                        <g fill="none" fillRule="evenodd">
                          <circle cx={16} cy={16} r={16} fill="#26a17b"></circle>
                          <path fill="#fff" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042c-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658s2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061c1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658c0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118s3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116s-3.301-1.914-7.694-2.117"></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>

              </div>

              {/* Timer */}
              <div className="z-10 mt-6 grid grid-flow-col gap-3 text-center auto-cols-max">
                <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                  <span className="countdown font-mono text-4xl">
                    <span style={{ "--value": days }}></span>
                  </span>
                  days
                </div>
                <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                  <span className="countdown font-mono text-4xl">
                    <span style={{ "--value": hours }}></span>
                  </span>
                  hours
                </div>
                <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                  <span className="countdown font-mono text-4xl">
                    <span style={{ "--value": minutes }}></span>
                  </span>
                  min
                </div>
                <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
                  <span className="countdown font-mono text-4xl">
                    <span style={{ "--value": seconds }}></span>
                  </span>
                  sec
                </div>
              </div>
              <p className='p-1 px-2 text-sm font-semibold bg-gray-700 bg-opacity-80 mt-3 rounded-lg text-white'>Referred By {user.referrer.slice(0,8)}...{user.referrer.slice(38)}</p>

            </div>
          </div>
        </div>

        <div className="md:mt-0 mt-4 ml-0 md:ml-4 w-full md:w-2/3 flex flex-col items-center">
          <div className="flex items-center w-full">
            <div className="flex flex-col rounded-l-lg overflow-hidden justify-center items-center w-1/3 bg-black p-4 h-28 relative">
              <img
                src="https://plus.unsplash.com/premium_photo-1677529496297-fd0174d65941?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Team Icon"
                className="rounded-l-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
              />
              <span className="text-center font-bold z-20">Total Team</span>
              <span className="font-bold z-20 text-xl md:text-2xl">{user.totalTeam}</span>
            </div>
            <div className="flex flex-col overflow-hidden justify-center items-center w-1/3 bg-black p-4 h-28 relative">
              <img
                src="https://plus.unsplash.com/premium_photo-1677529497048-2bf5899e68de?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Team Icon"
                className="absolute left-0 top-0 min-w-full min-h-full opacity-50"
              />
              <span className="text-center font-bold z-20">Direct Team</span>
              <span className="font-bold z-20 text-xl md:text-2xl">{user.directTeam}</span>
            </div>
            <div className="rounded-r-lg flex flex-col overflow-hidden justify-center items-center w-1/3 bg-black p-4 h-28 relative">
              <img
                src="https://images.unsplash.com/photo-1529209076408-5a115ec9f1c6?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Team Icon"
                className="rounded-r-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
              />
              <span className="text-center font-bold z-20">Community Team</span>
              <span className="font-bold z-20 text-xl md:text-2xl">
                {user.matrixTeam}
              </span>
            </div>
          </div>

          {/* Refer Link */}
          <div className="mt-5 orange-gradient rounded-lg w-full flex flex-col overflow-hidden justify-center items-start bg-black p-2 px-3 relative">
            <span className="font-bold z-20">Refer a friend</span>
            <CopyToClipboard onCopy={() => message.success("Copied")} text={`https://myfortune.me/dashboard?ref=${useAppState.walletAddress}`} >
              <span className="flex items-center mt-2 rounded-md font-semibold z-20 text-sm bg-gray-800 p-2 bg-opacity-40">
                <p className='mr-2'>myfortune.me/dashboard?ref={useAppState.walletAddress.slice(0,6)}...{useAppState.walletAddress.slice(39)}</p>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="white" fillRule="evenodd" d="M3 3a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H5v12a1 1 0 1 1-2 0zm4 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a3 3 0 0 1-3 3h-8a3 3 0 0 1-3-3z" clipRule="evenodd"></path></svg>
              </span>
            </CopyToClipboard>
          </div>

          <div className="flex items-center w-full mt-4">
            <div className="flex flex-col rounded-l-lg overflow-hidden justify-center items-center w-1/3 bg-black p-4 h-28 relative">
              <img
                src="https://img.freepik.com/premium-photo/business-arrow-increase-success-graph-growth-stock-market-earnings-financial-profit-income-background-with-diagram-chart-investment_79161-1805.jpg"
                alt="Team Icon"
                className="rounded-l-lg absolute left-0 top-0 min-w-full min-h-full opacity-60"
              />
              <span className="text-center font-bold z-20">Total Revenue</span>
              <span className="font-bold z-20 text-xl md:text-2xl">${user.revenue.toFixed(2)}</span>
            </div>
            <div className="flex flex-col overflow-hidden justify-center items-center w-1/3 bg-black p-4 h-28 relative">
              <img
                src="https://images.unsplash.com/photo-1553729459-efe14ef6055d?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Team Icon"
                className="rounded-r-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
              />
              <span className="text-center font-bold z-20">Total Business</span>
              <span className="font-bold z-20 text-xl md:text-2xl">${user.totalBusiness}</span>
            </div>
            <div className="rounded-r-lg flex flex-col overflow-hidden justify-center items-center w-1/3 bg-black p-4 h-28 relative">
              <img
                src="https://t4.ftcdn.net/jpg/08/64/90/75/360_F_864907530_X7lv7MKApS8qiUJnEhS1pIOrfTWHca3W.jpg"
                alt="Team Icon"
                className="rounded-r-lg absolute left-0 top-0 min-w-full min-h-full opacity-60"
              />
              <span className="text-center font-bold z-20">Auto Upgrade</span>
              <span className="font-bold z-20 text-xl md:text-2xl">
                ${user.autoUpgrade.toFixed(2)}
              </span>
            </div>
          </div>
          
          <div className="flex mt-5 flex-col rounded-l-lg overflow-hidden items-start w-full bg-black p-4 relative">
            <img
              src="https://img.freepik.com/premium-vector/financial-business-statistics-with-bar-graph-candlestick-chart-show-effective-earning-background_120819-1075.jpg"
              alt="Team Icon"
              className="rounded-l-lg absolute left-0 top-0 min-w-full min-h-full opacity-60"
            />
            <p className='z-20 font-semibold'>Weekly Direct ({(user.weeklyDirect[0] > user.weeklyDirect[1] ? user.weeklyDirect[1] : user.weeklyDirect[0])}/{user.weeklyDirect[1]})</p>
            <div className='z-20 mt-2 w-full bg-gray-200 h-2'><div style={{width: `${((user.weeklyDirect[0] > user.weeklyDirect[1] ? user.weeklyDirect[1] : user.weeklyDirect[0]) * 100)/user.weeklyDirect[1]}%`}} className='bg-green-500 h-full'></div></div>
          </div>

          <div className="flex mt-4 flex-col rounded-l-lg overflow-hidden items-start w-full bg-black p-4 relative">
            <img
              src="https://img.freepik.com/premium-photo/business-arrow-increase-success-graph-growth-stock-market-earnings-financial-profit-income-background-with-diagram-chart-investment_79161-1805.jpg"
              alt="Team Icon"
              className="rounded-l-lg absolute left-0 top-0 min-w-full min-h-full opacity-60"
            />
            <p className='z-20 flex items-center gap-y-2 font-bold'><span className='mr-2 font-semibold'>Royalty Slots ({(user.slots > 6 ? 6 : user.slots)}/6)</span></p>
            <div className='z-20 mt-2 w-full bg-gray-200 h-2'><div style={{width: `${((user.slots > 6 ? 6 : user.slots) * 100)/6}%`}} className='bg-green-500 h-full'></div></div>
          </div>

          {isAirdropAvl ? 
          <div className='w-full'>
            <div className='bloody-mary mt-4 w-full md:w-2/3 flex justify-between p-3 py-6 rounded-xl'>
              <div className=''>
                  <h3 className='text-sm font-semibold'>Airdrop Reward</h3>
                  <p className='font-bold'>{user.airdrop.toFixed(3)}/100 FAI</p>
                  <p className='font-bold'>${airdropClaimedInUsd.toFixed(2)}/${faiVals.toFixed(4)}</p>
              
                  <p className="font-bold px-3 text-sm bloody-mary rounded-md text-center mt-3 py-1">{user.slots >= 3 ? useAppState.formatTime(time2) : 
                    <>
                      <p className='z-20 font-semibold'>Slots ({(user.slots > 3 ? 3 : user.slots)}/3)</p>
                      <div className='z-20 mt-2 w-full bg-gray-200 h-2'><div style={{width: `${((user.slots > 3 ? 3 : user.slots) * 100)/3}%`}} className='bg-green-500 h-full'></div></div>
                    </>
                  }</p>
              </div>

              <div className='ml-4 border-l-[1px] border-gray-300 h-full'></div>
              <div>
                  <p className='text-sm font-bold'>Claimable: {user.claimable.toFixed(3)} FAI</p>

                  {loading ?
                  <button className='flex justify-center items-center mt-2 bg-white rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-200 text-black'>
                      <TailSpin color='black' height={20} />
                  </button> :
                  <button onClick={claim} className='flex justify-center items-center mt-2 bg-white rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-200 text-black'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 48 48"><g fill="none" strokeWidth={4}><path stroke="white" d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z" clipRule="evenodd"></path><path fill="#2eff58" stroke="white" strokeLinejoin="round" d="M24 44C35.0457 44 44 35.0457 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44Z"></path><path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" d="M33 27L24 18L15 27"></path></g></svg>
                      <span className='ml-3'>Claim</span>
                  </button>
                  }          
              </div>
            </div>
            </div>
          : null}

        </div>
      </div>
    </div>
  );
}

export default UserInfo
