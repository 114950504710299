import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';

const TransferHistory = () => {
  const useAppState = useContext(AppState);
  const [data, setData] = useState([
    {
      sno: 0,
      amount: 0,
      from: "",
      time: 0,
      to: "",
      transferType: 0
    },
  ]);
  const [transferType, setTransferType] = useState(["Send ↗", "Received ↙", "vUsdt", "vUsdt Debt"]);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _team = await contract.getTransferHistory(useAppState.walletAddress);
      setData([]);
      let arr = [];
      for(let i=_team.length - 1; i>=0; i--) {
        arr.push({
          sno: i+1,
          amount: useAppState.convert(_team[i].amount),
          from: _team[i].from,
          time: Number(_team[i].time) * 1000,
          to: _team[i].to,
          transferType: Number(_team[i].transferType)
        })
      }
      setData(arr);
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change])

  return (
    <div className='min-h-screen mt-6 w-full'>
      <div className="w-full flex justify-center">
        <div className="w-full">
            <div className="overflow-x-auto">
              <table className="table table-zebra table-md">
                {/* head */}
                <thead className='bg-blue-500 text-white'>
                  <tr>
                    <th></th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                {data.map((e, i) => {
                  return (
                  <tr className='whitespace-nowrap'>
                    <th>{e.sno}</th>
                    <th>{e.transferType == 0 ? <span className='text-red-500'>Send ↗</span> : e.transferType == 1 ? <span className='text-green-500'>Received ↙</span> : e.transferType == 2 ? <span className='text-blue-500'>vUSDT</span> : <span className='text-orange-500'>vUSDT Debt</span>}</th>
                    <td>${e.amount}</td>
                    <td>{e.from.slice(0,8)}...{e.from.slice(38)}</td>
                    <td>{e.to.slice(0,8)}...{e.to.slice(38)}</td>
                    <td>{new Date(e.time).toLocaleString()}</td>
                  </tr>
                )})}
                </tbody>
              </table>
            </div>

        </div>
      </div>
    </div>
  )
}

export default TransferHistory