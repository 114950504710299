import React, { useContext, useEffect, useState } from 'react'
import UserInfo from './UserInfo'
import { message, Slider } from 'antd'
import Slots from './Slots'
import Pools from './Pools'
import { AppState } from '../App'
import NonWorkingPool from './NonWorkingPool'
import LifestyleFund from './LifestyleFund'

const Dashboard = () => {
  return (
    <div className='w-full'>
      <UserInfo />
      <Slots />
      <NonWorkingPool />
      <LifestyleFund />
      <Pools />
    </div>
  )
}

export default Dashboard