import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';

const Teams = () => {
  const useAppState = useContext(AppState);
  const [layer, setLayer] = useState(1);
  const [data, setData] = useState([
    {
      sno: 0,
      addr: "",
      referrer: "",
      joiningTime: 0,
      slotsPurchased: 0,
      totalDeposit: 0,
      directTeam: 0,
      directBusiness: 0,
      totalTeam: 0,
      totalBusiness: 0,
      virtualUsdtBalance: 0,
      useableVirtualUsdt: 0,
      usedVirtualUsdt: 0
    },
  ]);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _team = await contract.getTeamUsersLength(useAppState.walletAddress, layer - 1);
      setTimeout(() => console.log(""), 5000)
      setData([]);
      let arr = [];
      for(let i=Number(_team) - 1; i>=0; i--) {
        let _curUser = await contract.userInfo(await contract.teamUsers(useAppState.walletAddress, layer - 1, i));
        setData((prev) => [...prev, {
          sno: i+1,
          addr: _curUser.addr,
          referrer: _curUser.referrer,
          joiningTime: Number(_curUser.joiningTime) * 1000,
          slotsPurchased: Number(_curUser.slotsPurchased),
          totalDeposit: useAppState.convert(_curUser.totalDeposit),
          directTeam: Number(_curUser.directTeam),
          directBusiness: useAppState.convert(_curUser.directBusiness),
          totalTeam: Number(_curUser.totalTeam),
          totalBusiness: useAppState.convert(_curUser.totalBusiness),
          virtualUsdtBalance: useAppState.convert(_curUser.virtualUsdtBalance),
          usedVirtualUsdt: useAppState.convert(_curUser.usedVirtualUsdt)
        }])
      }
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change, layer])

  return (
    <div className='min-h-screen w-full'>
      <div className="w-full flex justify-center">
        <div className="w-full">
            <div className='flex overflow-x-auto p-2'>
              <p onClick={() => setLayer(1)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 1 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-1`}>1</p>
              <p onClick={() => setLayer(2)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 2 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>2</p>
              <p onClick={() => setLayer(3)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 3 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>3</p>
              <p onClick={() => setLayer(4)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 4 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>4</p>
              <p onClick={() => setLayer(5)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 5 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>5</p>
              <p onClick={() => setLayer(6)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 6 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>6</p>
              <p onClick={() => setLayer(7)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 7 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>7</p>
              <p onClick={() => setLayer(8)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 8 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>8</p>
              <p onClick={() => setLayer(9)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 9 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>9</p>
              <p onClick={() => setLayer(10)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 10 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>10</p>
              <p onClick={() => setLayer(11)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 11 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>11</p>
              <p onClick={() => setLayer(12)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 12 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>12</p>
              <p onClick={() => setLayer(13)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 13 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>13</p>
              <p onClick={() => setLayer(14)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 14 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>14</p>
              <p onClick={() => setLayer(15)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 15 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>15</p>
              <p onClick={() => setLayer(16)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 16 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>16</p>
              <p onClick={() => setLayer(17)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 17 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>17</p>
              <p onClick={() => setLayer(18)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 18 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>18</p>
              <p onClick={() => setLayer(19)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 19 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>19</p>
              <p onClick={() => setLayer(20)} className={`cursor-pointer z-10 rounded-md font-semibold hover:bg-indigo-500 hover:text-white px-4 py-2 text-xs ${layer == 20 ? "bg-indigo-500 text-white" : "bg-white text-black"} ml-2`}>20</p>
            </div>

            <div className="overflow-x-auto mt-6">
              <table className="table table-zebra table-md">
                {/* head */}
                <thead className='bg-blue-500 text-white'>
                  <tr>
                    <th></th>
                    <th>Address</th>
                    <th>Referrer</th>
                    <th>Joining Time</th>
                    <th>Slot Purchased</th>
                    <th>Direct Team</th>
                    <th>Total Team</th>
                    <th>Total Business</th>
                  </tr>
                </thead>
                <tbody>
                {data.map((e, i) => {
                  return (
                  <tr className='whitespace-nowrap'>
                    <th>{e.sno}</th>
                    <td>{e.addr.slice(0, 7)}...{e.addr.slice(37)}</td>
                    <td>{e.referrer.slice(0, 8)}...{e.referrer.slice(38)}</td>
                    <td>{new Date(e.joiningTime).toLocaleString()}</td>
                    <td>{e.slotsPurchased}</td>
                    <td>{e.directTeam}</td>
                    <td>{e.totalTeam}</td>
                    <td>${e.totalBusiness}</td>
                  </tr>
                )})}
                </tbody>
              </table>
            </div>

          {/* <div class="overflow-x-scroll mt-4 w-full rounded-lg dark:text-white text-gray-800">
            <table class="w-full whitespace-nowrap">
              <thead class="bg-[#8080821a]">
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">SNo.</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Address</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Sponser</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Joining Time</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Slot Purchased</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Direct Team</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Total Team</th>
                <th class="text-center font-bold py-3 px-2 text-white whitespace-nowrap bg-blue-500">Total Business</th>
              </thead>
              {data.map((e, i) => {
                return (
                  <tr key={i} class="">
                    <td class="text-center justify-center border-b-1 border-[rgba(89,124,240,0.16)] text-white py-3 px-2">
                        {e.sno}
                    </td>

                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">
                      {e.addr.slice(0, 7)}...{e.addr.slice(37)}
                    </td>
                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">
                      {e.referrer.slice(0, 8)}...{e.referrer.slice(38)}
                    </td>
                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">
                      {new Date(e.joiningTime).toLocaleString()}
                    </td>
                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">{e.slotsPurchased}</td>
                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">{e.directTeam}</td>
                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">{e.totalTeam}</td>
                    <td class="text-center py-3 px-2 border-b-1 border-[rgba(89,124,240,0.16)] text-white">${e.totalBusiness}</td>
                  </tr>
                );
              })}
            </table>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default Teams