import { TailSpin } from 'react-loader-spinner';
import {AppState} from '../App'
import React, { useContext, useEffect, useState } from 'react'
import { message } from 'antd';
import { ethers } from 'ethers';

const Admin = () => {
    const useAppState = useContext(AppState)
    const [index, setIndex] = useState(0);
    const [val, setVal] = useState(0);
    const [addr, setAddr] = useState("");
    const [type, setType] = useState(0);
    const [loading, setLoading] = useState(false);

    const [tokenVal, setTokenVal] = useState(0);
    const [tokenIn, setTokenIn] = useState("");
    const [tokenOut, setTokenOut] = useState("");
    const [loading2, setLoading2] = useState("");

    const [type2, setType2] = useState(0);
    const [val2, setVal2] = useState(0);
    const [tokenAddr, setTokenAddr] = useState("");
    const [receiver, setReceiver] = useState("");
    const [loading3, setLoading3] = useState(false)

    const [polAmt2, setPolAmt2] = useState("");
    const [tokenOut2, setTokenOut2] = useState("");
    const [loading4, setLoading4] = useState(false);

    const update = async () => {
        setLoading(true);
        try {
            let contract = await useAppState.getContract();
            await contract.setPercent(type, index, val, addr);
            message.success("Updated");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading(false);
    }

    const Swap = async () => {
        setLoading2(true);
        try {
            let contract = await useAppState.getBackupContract();
            await contract.swap(tokenVal, tokenIn, tokenOut);
            message.success("Swapped");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading2(false);
    }

    const SwapPol = async () => {
        setLoading4(true);
        try {
            let contract = await useAppState.getBackupContract();
            await contract.swapPol(ethers.parseEther(polAmt2), "0x6701F0318EA6689c526AB47FE0E5F0A34a524D06");
            message.success("Swapped");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading4(false);
    }

    const Transfer = async () => {
        setLoading3(true);
        try {
            let contract = await useAppState.getBackupContract();
            await contract.setAddr(type2, val2, tokenAddr, receiver);
            message.success("Swapped");
        } catch (error) {
            message.error(error.reason);
        }
        setLoading3(false);
    }

  return (
    <div className="min-h-screen w-full">
      <div class="w-full">
            <h1 className='font-bold text-sm'>Main Contract</h1>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Type</span>
                <input value={type} onChange={(e) => setType(e.target.value)} placeholder='Enter Type' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Index</span>
                <input value={index} onChange={(e) => setIndex(e.target.value)} placeholder='Enter Index' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Value</span>
                <input value={val} onChange={(e) => setVal(e.target.value)} placeholder='Enter Value' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Address</span>
                <input value={addr} onChange={(e) => setAddr(e.target.value)} placeholder='Enter Address' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <button onClick={update} className='bg-green-500 mt-6 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading ? <TailSpin height={22} color='white' /> : <p>Update</p>}</button>
      </div>

      {/* <div className='w-full mt-6'>
        <h1 className='font-bold text-sm'>BackupFunds Swap</h1>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Type</span>
                <input value={type} onChange={(e) => setType(e.target.value)} placeholder='Enter Type' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Token In</span>
                <input value={tokenIn} onChange={(e) => setTokenIn(e.target.value)} placeholder='Enter Address In' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Token Out</span>
                <input value={tokenOut} onChange={(e) => setTokenOut(e.target.value)} placeholder='Enter Address Out' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <button onClick={Swap} className='bg-green-500 mt-6 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading2 ? <TailSpin height={22} color='white' /> : <p>Swap</p>}</button>
      </div> */}

      <div className='w-full mt-6'>
        <h1 className='font-bold text-sm'>BackupFunds Swap POL</h1>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Pol Amount</span>
                <input value={polAmt2} onChange={(e) => setPolAmt2(e.target.value)} placeholder='Enter Pol Amount' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            {/* <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Token Out</span>
                <input value={tokenOut2} onChange={(e) => setTokenOut2(e.target.value)} placeholder='Enter Address Out' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div> */}
            <button onClick={SwapPol} className='bg-green-500 mt-6 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading4 ? <TailSpin height={22} color='white' /> : <p>Swap POL</p>}</button>
      </div>

      <div className='w-full mt-6'>
        <h1 className='font-bold text-sm'>BackupFunds Manage</h1>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Type</span>
                <input value={type2} onChange={(e) => setType2(e.target.value)} placeholder='Enter Token Value' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Value</span>
                <input value={val2} onChange={(e) => setVal2(e.target.value)} placeholder='Enter Token Value' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Token Address</span>
                <input value={tokenAddr} onChange={(e) => setTokenAddr(e.target.value)} placeholder='Enter Address In' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <div className="flex flex-col w-full mt-4">
                <span className='font-mono font-semibold'>Receiver</span>
                <input value={receiver} onChange={(e) => setReceiver(e.target.value)} placeholder='Enter Address Out' className='font-semibold w-full mt-2 outline-none bg-gray-200 text-gray-800 p-2 rounded-sm' />
            </div>
            <button onClick={Transfer} className='bg-green-500 mt-6 text-white w-full px-4 py-2 text-sm font-bold rounded-sm flex justify-center items-center'>{loading3 ? <TailSpin height={22} color='white' /> : <p>Transfer</p>}</button>
      </div>

    </div>
  )
}

export default Admin