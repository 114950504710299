import { useContext, useEffect, useState, useRef } from "react"
import { AppState } from "../App"
import { message } from "antd"

const LifestyleFund = () => {
  const useAppState = useContext(AppState)
  const intervalRef = useRef()
  const slotValue = [40, 70, 200, 1000, 2000, 4000, 8000, 15000, 30000]
  const [claimable, setClaimable] = useState(0)
  const [time, setTime] = useState(0)
  const [lastClaimed, setLastClaimed] = useState(0)
  const [loading, setLoading] = useState(false)

  const requiredSlot = 5
  const [slotsPurchased, setSlotsPurchased] = useState(0)

  const [income, setIncome] = useState(0)
  const [incomeLimit, setIncomeLimit] = useState(0)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (Number(lastClaimed) > 0) {
        setTime(Date.now() - Number(lastClaimed))
      } else {
        setTime(0)
      }
    }, 10)
    return () => clearInterval(intervalRef.current)
  }, [lastClaimed])

  useEffect(() => {
    async function getData() {
      const contract = await useAppState.getContract()
      const _nonWorkingData = await contract.claimLifestyle.staticCall(useAppState.walletAddress)
      setClaimable(useAppState.convert(_nonWorkingData))

    const _userInfo = await contract.userInfo(useAppState.walletAddress)
    setSlotsPurchased(Number(_userInfo.slotsPurchased))

    // const curDeposit = slotValue[Number(_userInfo.slotsPurchased) - 1]
    setIncomeLimit(2000)

    const _incomeInfo = await contract.incomeInfo(useAppState.walletAddress)
    setIncome(useAppState.convert(_incomeInfo.totalIncome))

    const _lastClaimed = await contract.lifestyleLastClaimed(useAppState.walletAddress)
    setLastClaimed(Number(_lastClaimed) * 1000)
    }
    getData()
  }, [useAppState.change, useAppState.walletAddress])

  const handleClaim = async () => {
    setLoading(true)
    try {
      const contract = await useAppState.getContract()
      const tx = await contract.claimLifestyle(useAppState.walletAddress)
      await tx.wait()
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Claimed")
    } catch (error) {
      message.error(error.reason)
    }
    setLoading(false)
  }

  const slotProgress = (slotsPurchased / requiredSlot) * 100
  const incomeProgress = (income / incomeLimit) * 100
  const meetsSlotRequirement = slotsPurchased >= requiredSlot

  return (
    <div className="w-full mt-6 overflow-hidden bg-gradient-to-br from-cyan-50 to-blue-50 rounded-xl border border-blue-100/50 shadow-lg relative">
      {/* Decorative elements */}
      <div className="absolute top-0 left-0 w-32 h-32 bg-gradient-to-br from-teal-400/10 to-blue-500/10 rounded-br-full"></div>
      <div className="absolute bottom-0 right-0 w-40 h-40 bg-gradient-to-tl from-blue-400/10 to-teal-500/10 rounded-tl-full"></div>

      {/* Header */}
      <div className="p-5 pb-2 relative z-10">
        <h2 className="text-2xl font-bold bg-gradient-to-r from-teal-600 to-blue-600 bg-clip-text text-transparent">
          Lifestyle Fund
        </h2>
        <p className="text-sm text-gray-500">Unlock rewards by meeting slot requirements</p>
      </div>

      {/* Content */}
      <div className="p-5 space-y-6 relative z-10">
        {/* Slot Progress - Always shown as primary requirement */}
        {!meetsSlotRequirement && <div className="bg-white/90 rounded-xl p-5 shadow-md border border-blue-100">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 text-blue-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="3" y1="9" x2="21" y2="9"></line>
                <line x1="9" y1="21" x2="9" y2="9"></line>
              </svg>
              <span className="font-semibold text-gray-700">Slot Progress</span>
            </div>
            <span className="text-sm font-medium text-blue-600">
              {slotsPurchased} / {requiredSlot}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-3 mb-1">
            <div
              className={`h-3 rounded-full transition-all duration-300 ${
                meetsSlotRequirement ? "bg-gradient-to-r from-teal-500 to-blue-500" : "bg-blue-400"
              }`}
              style={{ width: `${Math.min(slotProgress, 100)}%` }}
            ></div>
          </div>
          <p className={`text-sm ${meetsSlotRequirement ? "text-teal-600" : "text-gray-500"}`}>
            {meetsSlotRequirement
              ? "✓ Slot requirement met! Rewards unlocked."
              : `Need ${requiredSlot - slotsPurchased} more slot(s) to unlock rewards.`}
          </p>
        </div>}

        {/* Only show these if slot requirement is met */}
        {meetsSlotRequirement && (
          <>
            {/* Claimable Reward */}
            <div className="bg-white/90 rounded-xl p-4 shadow-md border border-blue-100">
              <div className="flex items-center gap-2 mb-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-teal-500"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                </svg>
                <span className="font-semibold text-gray-700">Claimable Reward</span>
              </div>
              <div className="text-2xl font-bold text-teal-600">${claimable.toFixed(2)}</div>
            </div>

            {/* Timer Section */}
            <div className="bg-white/90 rounded-xl p-4 shadow-md border border-blue-100">
              <div className="flex items-center gap-2 mb-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-blue-500"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <polyline points="12 6 12 12 16 14"></polyline>
                </svg>
                <span className="font-semibold text-gray-700">Next Claim Available In</span>
              </div>
              <div className="text-xl font-mono font-bold text-blue-600">
                {/* Timer placeholder - user will implement */}
                <div id="timer-placeholder" className="h-8 flex items-center">
                  {useAppState.formatTime(time)}
                </div>
              </div>
            </div>

            {/* Income Progress */}
            <div className="bg-white/90 rounded-xl p-4 shadow-md border border-blue-100">
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-teal-500"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                  </svg>
                  <span className="font-semibold text-gray-700">Income Progress</span>
                </div>
                <span className="text-sm font-medium text-teal-600">
                  ${income.toFixed(6)} / ${incomeLimit.toFixed(6)}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-gradient-to-r from-blue-400 to-teal-500 h-2.5 rounded-full transition-all duration-300"
                  style={{ width: `${Math.min(incomeProgress, 100)}%` }}
                ></div>
              </div>
              {income >= incomeLimit && (
                <p className="text-xs mt-1 text-teal-600 font-medium">
                  Income limit reached!
                </p>
              )}
            </div>

            {/* Claim Button */}
            <button
              onClick={handleClaim}
              disabled={claimable <= 0 || loading}
              className={`w-full py-3.5 px-4 rounded-lg font-medium text-white transition-all duration-200 flex items-center justify-center ${
                claimable <= 0 || loading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-gradient-to-r from-teal-500 to-blue-500 hover:from-teal-600 hover:to-blue-600"
              }`}
            >
              {loading ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Processing...
                </>
              ) : (
                "Claim Rewards"
              )}
            </button>
          </>
        )}
      </div>
    </div>
  )
}

export default LifestyleFund

