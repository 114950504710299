import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';
import { useTimer } from 'react-timer-hook';
import { TailSpin } from 'react-loader-spinner';
import { message } from 'antd';
import moment from 'moment';

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

const Pools = () => {
    const useAppState = useContext(AppState);
    const [weekly, setWeekly] = useState({
        time: 0,
        users: [],
        reward: 0,
        lastDist: 0
    })

    const [monthly, setMonthly] = useState({
        time: 0,
        users: [],
        reward: 0,
        lastDist: 0
    })
    const [showTimer, setShowTimer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    useEffect(() => {
        async function getData() {
            setShowTimer(false);
            let contract = await useAppState.getContract();
            setWeekly({
                time: Number(await contract.getWeeklyTimer()) * 1000,
                users: Number(await contract.getWeeklyAchievers(await contract.curWeek())),
                reward: useAppState.convert(await contract.weeklyReward()),
                lastDist: Number(await contract.weeklyLastDist()) * 1000
            })
            
            setMonthly({
                time: Number(await contract.getMonthlyTimer()) * 1000,
                users: Number(await contract.totalRoyaltyUsers()),
                reward: useAppState.convert(await contract.royaltyReward()),
                lastDist: Number(await contract.royaltyLastDist()) * 1000
            })

            setShowTimer(true);
        }
        getData();
    },[useAppState.walletAddress, useAppState.change])

    const distributeWeekly = async () => {
        setLoading(true);
        try {
          let contract = await useAppState.getContract();
          let tx = await contract.distributeWeeklyIncome();
          await tx.wait();
          useAppState.setChange(useAppState.change + 1);
          message.success("Sucessfully Distributed");
        } catch (error) {
          message.error(error.reason);
        }
        setLoading(false);
      }

    const distributeRoyalty = async () => {
        setLoading2(true);
        try {
          let contract = await useAppState.getContract();
          let tx = await contract.distributeRoyaltyIncome();
          await tx.wait();
          useAppState.setChange(useAppState.change + 1);
          message.success("Sucessfully Distributed");
        } catch (error) {
          message.error(error.reason);
        }
        setLoading2(false);
      }

  return (
    <div className='mt-6 w-full'>
        <div className='mt-12 w-full flex flex-col md:flex-row justify-between'>
            <div className='w-full md:w-1/2 flex justify-between p-3 bluelagoo rounded-xl'>
                <div>
                    <h3 className='text-xs md:text-sm font-semibold'>Weekly Countdown</h3>
                    <p className='text-lg font-bold'>{showTimer ? <MyTimer expiryTimestamp={weekly.time} /> : null}</p>
                    <h3 className='text-sm font-semibold mt-3'>Weekly Achievers</h3>
                    <p className='text-lg font-bold'>{weekly.users}</p>
                </div>
                <div className='ml-4 border-l-[1px] border-gray-300 h-full'></div>
                <div>
                    <h3 className='text-xs md:text-sm font-semibold'>Weekly Pool</h3>
                    <p className='text-lg font-bold mt-1'>${weekly.reward}</p>
                    {loading ?
                    <button className='flex justify-center items-center mt-3 bg-white rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-200 text-black'>
                        <TailSpin color='black' height={20} />
                    </button> :
                    (moment().valueOf() > weekly.time ? <button onClick={distributeWeekly} className='flex justify-center items-center mt-3 bg-white rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-200 text-black'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="#2ab927" d="M12 11q.825 0 1.413-.587T14 9t-.587-1.412T12 7t-1.412.588T10 9t.588 1.413T12 11M7 21v-2h4v-3.1q-1.225-.275-2.187-1.037T7.4 12.95q-1.875-.225-3.137-1.637T3 8V7q0-.825.588-1.412T5 5h2V3h10v2h2q.825 0 1.413.588T21 7v1q0 1.9-1.263 3.313T16.6 12.95q-.45 1.15-1.412 1.913T13 15.9V19h4v2zm0-10.2V7H5v1q0 .95.55 1.713T7 10.8m10 0q.9-.325 1.45-1.088T19 8V7h-2z"></path></svg>
                        <span className='ml-3'>Distribute</span>
                    </button>: null)
                    }          
                </div>
            </div>

            <div className='w-full md:w-1/2 ml-0 md:ml-6 mt-4 md:mt-0 flex justify-between p-3 easymed rounded-xl'>
                <div>
                    <h3 className='text-xs md:text-sm font-semibold'>Royalty Countdown</h3>
                    <p className='text-lg font-bold'>{showTimer ? <MyTimer expiryTimestamp={monthly.time} /> : null}</p>
                    <h3 className='text-sm font-semibold mt-3'>Royalty Achievers</h3>
                    <p className='text-lg font-bold'>{monthly.users}</p>
                </div>
                <div className='ml-4 border-l-[1px] border-gray-300 h-full'></div>
                <div>
                    <h3 className='text-xs md:text-sm font-semibold'>Royalty Pool</h3>
                    <p className='text-lg font-bold mt-1'>${monthly.reward}</p>
                    {loading2 ?
                    <button className='flex justify-center items-center mt-3 bg-white rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-200 text-black'>
                        <TailSpin color='black' height={20} />
                    </button> :
                    (moment().valueOf() > monthly.time ?
                    <button onClick={distributeRoyalty} className='flex justify-center items-center mt-3 bg-white rounded-lg py-2 px-4 text-sm font-semibold hover:bg-gray-200 text-black'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="#2ab927" d="M12 11q.825 0 1.413-.587T14 9t-.587-1.412T12 7t-1.412.588T10 9t.588 1.413T12 11M7 21v-2h4v-3.1q-1.225-.275-2.187-1.037T7.4 12.95q-1.875-.225-3.137-1.637T3 8V7q0-.825.588-1.412T5 5h2V3h10v2h2q.825 0 1.413.588T21 7v1q0 1.9-1.263 3.313T16.6 12.95q-.45 1.15-1.412 1.913T13 15.9V19h4v2zm0-10.2V7H5v1q0 .95.55 1.713T7 10.8m10 0q.9-.325 1.45-1.088T19 8V7h-2z"></path></svg>
                        <span className='ml-3'>Distribute</span>
                    </button> : null )
                    }          
                </div>
            </div>
        </div>
    </div>
  )
}

export default Pools