import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../App";
import { Bars } from "react-loader-spinner";
import { message, Checkbox } from "antd";
import { ethers } from "ethers";

const GreenTick = () => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2.5"
      class="w-3 h-3"
      viewBox="0 0 24 24"
    >
      <path d="M20 6L9 17l-5-5"></path>
    </svg>
  );
};

const UserSvg = ({fillColor}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 448 512"><path fill={fillColor} d="M224 256a128 128 0 1 0 0-256a128 128 0 1 0 0 256m-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7c0-98.5-79.8-178.3-178.3-178.3z"></path></svg>
  )
}

const CrossSvg = () => {
  return (
    <svg class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="currentColor" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="m11.25 4.75l-6.5 6.5m0-6.5l6.5 6.5"></path></svg>
  )
}

const Slots = () => {
  const useAppState = useContext(AppState);
  const [usersSlots, setUsersSlots] = useState(0);
  const [loading, setLoading] = useState(false)
  const [useToken, setUseToken] = useState(false);
  const [useOldToken, setUseOldToken] = useState(false);
  const [bal, setBal] = useState({
    autoUpgarde: 0,
    vUsd: 0,
    vUsdBonus: 0,
    tokenBal: 0
  })

  const [slots, setSlots] = useState([
    40, 70, 200, 1000, 2000, 4000, 8000, 15000, 30000
  ]);

  const [APLvl, setAPLvl] = useState([[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0],[0,0,0,0]])

  const [APTeam, setAPTeam] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

  const [useDCToken, setUseDCToken] = useState(false);

  const [selected, setSelected] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
  });

  const [final, setFinal] = useState({
    slotsNo: 0,
    slosAmount: 0,
  });

  const [useableAmt, setUseableAmt] = useState([0, 0, 0, 0, 0, 0]);
  const [useableLoading, setUseAbleLoading] = useState(false);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _user = await contract.userInfo(useAppState.walletAddress);
      setUsersSlots(Number(_user.slotsPurchased));

      let tokenContract = await useAppState.getRtrContract();
      setBal({
        autoUpgarde: useAppState.convert(_user.autoUpgrade),
        vUsd: useAppState.convert(_user.virtualUsdtBalance),
        vUsdBonus: useAppState.convert(_user.vUsdReceived),
        tokenBal: useAppState.convert(await tokenContract.balanceOf(useAppState.walletAddress))
      })
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change]);

  useEffect(() => {
    getDepositRate();
  },[useToken, useOldToken, useDCToken, final.slosAmount])

  const getDepositRate = async () => {
    setUseAbleLoading(true);
    let contract = await useAppState.getContract();
    let tokenContract = await useAppState.getRtrContract();

    let useable = [0, 0, 0, 0, 0, 0];
    let usdAmt = final.slosAmount;

    if(usdAmt > bal.autoUpgarde) {
        usdAmt -= bal.autoUpgarde;
        useable[0] = bal.autoUpgarde;
    } else {
        useable[0] = usdAmt;
        usdAmt = 0;
    }

    let vUsdReceivedAmt = 0;
    let vUsduseAmt = 0;
    let isTokenUsed = false;

    let virtualUsdtUseage = (final.slosAmount * 25)/100;
    if(useOldToken) vUsdReceivedAmt = bal.vUsdBonus > virtualUsdtUseage ? virtualUsdtUseage : bal.vUsdBonus;  
    if(useToken) vUsduseAmt = bal.vUsd > virtualUsdtUseage - vUsdReceivedAmt ? virtualUsdtUseage - vUsdReceivedAmt : bal.vUsd;  

    if(usdAmt > vUsdReceivedAmt) {
      usdAmt -= vUsdReceivedAmt;
    } else {
      vUsdReceivedAmt = usdAmt;
      usdAmt = 0;
    }

    if(usdAmt > vUsduseAmt) {
      usdAmt -= vUsduseAmt;
    } else {
      vUsduseAmt = usdAmt;
      usdAmt = 0;
    }

    if(useDCToken) {
        let price = useAppState.convert(await contract.getPriceFromUniswapV2FAI.staticCall(ethers.parseUnits("1", "mwei")));
        let tokenUseAmt = usdAmt/price;
        if(useAppState.convert(await tokenContract.balanceOf(useAppState.walletAddress)) >= tokenUseAmt) {
            isTokenUsed = true;
            useable[3] = tokenUseAmt;
            usdAmt = 0;
        }
    } 

      useable[1] = vUsduseAmt;
      useable[2] = vUsdReceivedAmt;
      useable[4] = usdAmt;
      useable[5] = usdAmt;

      setUseableAmt(useable);
      setUseAbleLoading(false);
  }

  const select = (amt, i) => {
    if (selected[i] == true || i == 0 || usersSlots >= i) {
      setFinal((prev) => ({
        slotsNo: prev.slotsNo + 1,
        slosAmount: prev.slosAmount + amt,
      }));
      setSelected((prev) => ({ ...prev, [i+1]: true }));
    }
  };

  const deSelect = (amt, i) => {
    if (selected[i + 2] == false || i == slots.length - 1) {
      setFinal((prev) => ({
        slotsNo: prev.slotsNo - 1,
        slosAmount: prev.slosAmount - amt,
      }));
      setSelected((prev) => ({ ...prev, [i+1]: false }));

      setUseToken(false);
      setUseOldToken(false);
      setUseDCToken(false);
    }
  };

  const upgrade = async () => {
    setLoading(true);
    try {
        let usdtContract = await useAppState.getUsdtContract();
        if (useAppState.convert(await usdtContract.allowance(useAppState.walletAddress, useAppState.contractAddress)) < Number(final.slosAmount)) {
            let _tx = await usdtContract.approve(useAppState.contractAddress, ethers.parseUnits("10000", "mwei"));
            await _tx.wait();
        }

        let tokenContract = await useAppState.getRtrContract();
        if (useAppState.convert(await tokenContract.allowance(useAppState.walletAddress, useAppState.contractAddress)) < 1000000 && useDCToken) {
            let _tx = await tokenContract.approve(useAppState.contractAddress, ethers.parseUnits("100000", "mwei"));
            await _tx.wait();
        }

      let contract = await useAppState.getContract();
      let tx = await contract.buySlot(useAppState.walletAddress, useToken, useOldToken, useDCToken);
      await tx.wait();
      setFinal({
        slotsNo: 0,
        slosAmount: 0
      })

      setUseToken(false);
      setUseOldToken(false);
      setUseDCToken(false);

      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Purchased");
    } catch (error) {
      message.error(error.reason);
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <div className="mt-6 md:mt-10 w-full">
      <section class="text-gray-200 body-font">
        <div class="container">
          <div class="flex overflow-x-scroll overflow-y-hidden">
            {slots.map((e, i) => {
              return (
                <div class={`p-4 ${i==0 ? 'pl-0' : 'pl-4'} min-w-[280px]`}>
                  <div class="h-full bg-[#1e2026] p-6 rounded-lg border-2 border-indigo-500 flex flex-col relative overflow-hidden">
                    <img
                      src="https://www.shutterstock.com/image-illustration/abstract-technology-background-big-data-600nw-1666595941.jpg"
                      alt="Team Icon"
                      className="rounded-r-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
                    />
                    <span class="z-10 bg-teal-500 font-bold text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
                      {useAppState.levels[i]}
                    </span>
                    <h2 class="z-10 text-sm tracking-widest title-font mb-1 font-medium">
                      Slot - {i + 1}
                    </h2>
                    <h1 class="z-10 text-5xl font-bold text-pink-500 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
                      <span>${slots[i]}</span>
                    </h1>

                    <p class="z-10 flex text-2xl font-semibold items-center text-gray-200 mb-2">
                      <span class={`w-4 h-4 mr-2 flex items-center justify-center ${usersSlots >= i+1 ? "bg-green-500" : "bg-red-500"} text-white rounded-full`}>
                        {usersSlots >= i+1 ? <GreenTick /> : <CrossSvg />}
                      </span>
                      {useAppState.levels[i]}
                    </p>

                    {/* <p class="z-10 flex gap-x-2 items-center text-gray-200 mb-2">
                      <p className="flex flex-col justify-center items-center"><UserSvg fillColor={`${APTeam[i] >= 1 ? "greenyellow" : "currentColor"}`}/></p>
                      <p className="flex flex-col justify-center items-center"><UserSvg fillColor={`${APTeam[i] >= 2 ? "greenyellow" : "currentColor"}`}/></p>
                      <p className="flex flex-col justify-center items-center"><UserSvg fillColor={`${APTeam[i] >= 3 ? "greenyellow" : "currentColor"}`}/></p>
                      <p className="flex flex-col justify-center items-center"><UserSvg fillColor={`${APTeam[i] >= 4 ? "greenyellow" : "currentColor"}`}/></p>
                      <p className="flex flex-col justify-center items-center"><UserSvg fillColor={`${APTeam[i] >= 5 ? "greenyellow" : "currentColor"}`}/></p>
                      <p className="flex flex-col justify-center items-center"><UserSvg fillColor={`${APTeam[i] >= 6 ? "greenyellow" : "currentColor"}`}/></p>
                    </p> */}

                    {usersSlots >= i + 1 ?
                      <button class="z-10 mt-3 flex justify-center items-center text-white border-0 py-2 px-4 w-full focus:outline-none rounded">
                        ✅ Purchased
                      </button>
                    : (selected[i + 1] ? (
                      <button onClick={() => deSelect(e, i)} class="z-10 mt-3 flex justify-center items-center text-white border-0 py-2 px-4 w-full focus:outline-none rounded">
                        ✅ Selected
                      </button>
                    ) : (i == usersSlots ? 
                      <button
                        onClick={() => select(e, i)}
                        class="z-10 mt-3 flex items-center text-white bg-indigo-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-indigo-600 rounded"
                      >
                        Select
                        <svg
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          class="w-4 h-4 ml-auto"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                      </button>
                    : null))
                    }
                  </div>
                </div>
              );
            })}
          </div>

          {/* Button */}
          {final.slosAmount > 0 ?
            <div>
              <div className='flex pl-3 flex-col items-start justify-center'>
                <Checkbox className=" text-white mt-2 font-semibold" checked defaultChecked><div><div>Auto Upgrade</div> <div className='text-green-500'>- {bal.autoUpgarde} USD {useableAmt[0] > 0 ? `(Usable - ${useableAmt[0]})` : null}</div></div></Checkbox>
                <Checkbox checked={useToken} className=" text-white mt-2 font-semibold" onChange={(e) => {setUseToken(e.target.checked)}}><div><div>Use Virtual USDT Debt</div> <div className='text-green-500 flex items-center'>- {bal.vUsd} USD {useableAmt[1] > 0 ? `(usable - ${useableAmt[1]})` : null}</div></div></Checkbox>
                <Checkbox checked={useOldToken} className=" text-white mt-2 font-semibold" onChange={(e) => {setUseOldToken(e.target.checked)}}><div><div>Use Virtual USDT Bonus</div> <div className='text-green-500 flex items-center'>- {bal.vUsdBonus} USD {useableAmt[2] > 0 ? `(usable - ${useableAmt[2]})` : null}</div></div></Checkbox>
                <Checkbox checked={useDCToken} className=" text-white mt-2 font-semibold" onChange={(e) => {setUseDCToken(e.target.checked)}}><div><div>Use Token</div> <div className='text-green-500 flex items-center'>- {bal.tokenBal.toFixed(4)} FAI {useableAmt[3] > 0 ? `(usable - ${useableAmt[3].toFixed(4)})` : null}</div></div></Checkbox>
              </div>
              {loading || useableLoading  ?
                <button className="bg-lime-500/90 rounded-xl p-3 mt-4 w-full flex justify-center"><Bars color="white" height={20} /></button> :
                <button onClick={upgrade} className="bg-lime-500/90 rounded-xl p-3 mt-4 w-full font-bold">Buy Slots - (${useableAmt[4].toFixed(2)})</button>
              }
            </div>
          : null}
        </div>
      </section>
    </div>
  );
};

export default Slots;
