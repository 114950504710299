import { Routes, Route, useNavigate } from 'react-router-dom';
import Homepage from './components/Homepage'
import Layout from './components/Layout';
import Dashboard from './components/Dashboard'
import IncomeHistory from './components/IncomeHistory'
import Wallet from './components/Wallet'
import VirtualUsdt from './components/VirtualUsdt'
import { createContext } from 'react';
import Teams from './components/Teams';
import DCWorld from './artifacts/contracts/FortuneRide.sol/FortuneAIAgent.json'
import Backup from './artifacts/contracts/BackupFunds.sol/BackupFunds.json'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import Community from './components/Community';
import Tree from './components/Tree';
import GlobalSingleLeg from './components/GlobalSingleLeg';
import Admin from './components/Admin';
import Manage from './components/Manage';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';

const AppState = createContext();

const erc20Abi = [
  "function balanceOf(address) view returns (uint256)",
  "function totalSupply() view returns (uint256)",
  "function transfer(address, uint256) returns (bool)",
  "function allowance(address owner, address spender) view returns (uint256)",
  "function approve(address, uint256) returns (bool)",
  "function transferFrom(address, address, uint256) returns (bool)",
];

function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days
    } = useTimer({ expiryTimestamp});
  
    return <p><span>{days} Days, </span><span>{hours.toString().padStart(2, '0')}</span>:<span>{minutes.toString().padStart(2, '0')}</span>:<span>{seconds.toString().padStart(2, '0')}</span></p>
  }

function App() {
  const { ethereum } = window;
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [bal, setBal] = useState(0);
  const [bal2, setBal2] = useState(0);
  const [bal3, setBal3] = useState(0);
  const [change, setChange] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);
  const [levels, setLevels] = useState(["Starter", "Beginner", "Performer", "Pioneer", "Achiever", "Champion", "Mentor", "Icon", "Legend"]);
  const [isModalOpen, setModalOpen] = useState(false);

  const usdtAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F";
  const rtrAddress = "0xC61eD4944955d8b9626c28595940F3081cC80E71";
  const backupFunds = "0x00d88947c65E711Bc49473f01c864037df7016d1";
  const contractAddress = "0x03b86B30E7C0F0446Ca37C3347755706b28E5cdA";

  let provider;
  let signer;

  async function getContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(contractAddress, DCWorld.abi, signer);
  }

  async function getBackupContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(backupFunds, Backup.abi, signer);
  }

  async function getUsdtContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(usdtAddress, erc20Abi, signer);
  }

  async function getRtrContract() {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();
    return new ethers.Contract(rtrAddress, erc20Abi, signer);
  }

  useEffect(() => {
    // window.alert(moment().add(13, "hours").add(30, "minutes").valueOf())
    async function getWallet() {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });

        setWalletAddress(accounts[0]);
        // setWalletAddress("0x8ad84f8dB2BfdC36d4F380Cc2c7953eD87C92c3A");
        // setWalletAddress("0x0A1C6f336139D7ABf05B91f4cf98ed84F46adbb3");
        // setWalletAddress("0x42d1a4149dbc316bae5f10672c7759e1364b7215");
      } catch (error) {
        message.error("Install Web3 Wallet");
      }
    }

    getWallet();
  }, [])

  if (window.ethereum !== undefined) {
    ethereum.on("accountsChanged", (accounts) => {
      setWalletAddress(accounts[0]);
    });
  }

  useEffect(() => {
    async function getProvider() {
      if (walletAddress !== "") {
        provider = new ethers.BrowserProvider(window.ethereum);
        signer = await provider.getSigner();
        let _bal = await provider.getBalance(walletAddress);
        setBal(Number(ethers.formatEther(_bal)));

        let usdtContract = await getUsdtContract();
        let _bal2 = await usdtContract.balanceOf(walletAddress);
        setBal2(convert(_bal2))

        let rtrContract = await getRtrContract();
        let _bal3 = await rtrContract.balanceOf(walletAddress);
        setBal3(convert(_bal3))
      }
    }

    getProvider();
  }, [walletAddress, change])

  const convert = (val) => {
    return Number(ethers.formatUnits(val, "mwei"));
  }

  const formatTime = (time) => {
    const sec = Math.floor((time / 1000) % 60);
    const min = Math.floor((time / 60000) % 60);
    const hr = Math.floor((time / 3600000) % 24);
    const days = Math.floor(time / 86400000);
    return (
      `${days.toString().padStart(2, '0')} Days, ` +
      `${hr.toString().padStart(2, '0')} : ` +
      `${min.toString().padStart(2, '0')} : ` +
      `${sec.toString().padStart(2, '0')}`
    );
  };

  const Login = async () => {
    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      setWalletAddress(accounts[0]);
      let contract = await getContract();
      let user = await contract.userInfo(accounts[0]);
      if (user.referrer != "0x0000000000000000000000000000000000000000") {
        message.success("Logged In");
        navigate('/dashboard');
      } else {
        message.error("User Not Registered");
      }
    } catch (error) {
      message.error("Install Web3 Wallet");
    }
  }


  return (
    <AppState.Provider value={{ Login, levels, getBackupContract, getContract, getUsdtContract, getRtrContract, contractAddress, usdtAddress, rtrAddress, formatTime, convert, change, setChange, walletAddress, setWalletAddress, bal, bal2, bal3, isNewUser, setIsNewUser }}>

      <Modal title="Announcement" className='z-20' onCancel={() => setModalOpen(false)} cancelButtonProps={{ className: 'bg-blue-500 text-white' }} cancelText={"Got It!"} okButtonProps={{ className: 'hidden' }} open={isModalOpen} >
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-lg rounded-2xl">
          <h2 className="text-2xl font-bold text-center text-blue-600">Dear FAI Community</h2>

          <div className="mt-4 p-4 bg-blue-50 rounded-lg">
          Due to some technical issues, a few features of our site are temporarily paused. Our team is actively working to resolve the situation, and everything will be back to normal soon. We appreciate your patience and support during this time. Thank you for your cooperation!
           </div>

          <div className="mt-6 text-center font-bold text-xl">
            <MyTimer expiryTimestamp={1740506956860} />
          </div>
        </div>
      </Modal>

      <div className="flex flex-col justify-center items-center overflow-x-hidden">
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/dashboard' element={<Layout Component={Dashboard} />} />
          <Route path='/teams' element={<Layout Component={Teams} />} />
          <Route path='/community' element={<Layout Component={Community} />} />
          <Route path='/tree' element={<Layout Component={Tree} />} />
          <Route path='/virtualusdt' element={<Layout Component={VirtualUsdt} />} />
          <Route path='/wallet' element={<Layout Component={Wallet} />} />
          <Route path='/manage' element={<Layout Component={Manage} />} />
          <Route path='/don' element={<Layout Component={Admin} />} />
          <Route path='/incomehistory' element={<Layout Component={IncomeHistory} />} />
        </Routes>
      </div>

    </AppState.Provider>
  );
}

export default App;
export { AppState };
