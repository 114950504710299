import React, { useContext, useEffect, useState } from 'react'
import { AppState } from '../App';

const IncomeHistory = () => {
  const useAppState = useContext(AppState);
  const [layer, setLayer] = useState(1);
  const [incomes, setIncomes] = useState(["Direct", "Global Single Leg", "Weekly", "Royalty", "Upgrading", "Weekly Star Bonus", "Non Working", "Lifestyle"]);
  const [data, setData] = useState([
    {
      sno: 0,
      from: "",
      amount: 0,
      incomeType: 0,
      layer: 0,
      time: 0,
      isHold: false,
      slot: 0,
      isLost: true
    },
  ]);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _team = await contract.getIncomeHistory(useAppState.walletAddress);
      setData([]);
      let arr = [];
      for(let i=_team.length - 1; i>=0; i--) {
        arr.push({
          sno: i+1,
          from: _team[i].from,
          amount: useAppState.convert(_team[i].amount),
          incomeType: Number(_team[i].incomeType),
          layer: Number(_team[i].layer),
          time: Number(_team[i].time) * 1000,
          slot: Number(_team[i].slot) + 1,
          isLost: _team[i].isLost
        })
      }
      setData(arr);
    }
    getData();
  }, [useAppState.walletAddress, useAppState.change, layer])

  return (
    <div className='min-h-screen w-full'>
      <div className="w-full flex justify-center">
        <div className="w-full">
            <div className="overflow-x-auto">
              <table className="table table-zebra table-md">
                {/* head */}
                <thead className='bg-blue-500 text-white'>
                  <tr>
                    <th></th>
                    <th>amount</th>
                    <th>From</th>
                    <th>Income Type</th>
                    <th>Slot</th>
                    <th>Time</th>
                    <th>Layer</th>
                  </tr>
                </thead>
                <tbody>
                {data.map((e, i) => {
                  return (
                  <tr className='whitespace-nowrap'>
                    <th>{e.sno}</th>
                    <td className='text-green-500'>${e.amount.toFixed(4)}</td>
                    <td>{e.incomeType == 3 || e.incomeType == 2 || e.incomeType == 5 || e.incomeType == 6 || e.incomeType == 7 ? "System" : `${e.from.slice(0, 7)}...${e.from.slice(37)}`}</td>
                    <td className='flex items-center'>{incomes[e.incomeType]} Income {e.isLost ? <span className='text-xs text-red-500 ml-2'>(Lost)</span> : null}</td>
                    <td>{e.incomeType == 2 || e.incomeType == 3 || e.incomeType == 6 ? "--" : useAppState.levels[e.slot - 1]}</td>
                    <td>{new Date(e.time).toLocaleString()}</td>
                    <td>{e.incomeType == 0 ? "Refer" : (e.incomeType == 1 ? `Layer ${e.layer}` : (e.incomeType == 4 ? `Layer ${e.layer}` : "--"))}</td>
                  </tr>
                )})}
                </tbody>
              </table>
            </div>

        </div>
      </div>
    </div>
  )
}

export default IncomeHistory