import React, { useContext, useEffect, useState } from 'react'
import TransferHistory from './TransferHistory';
import { AppState } from '../App';
import { Bars } from 'react-loader-spinner';
import { message } from 'antd';
import { ethers } from 'ethers';

const VirtualUsdt = () => {
  const useAppState = useContext(AppState);
  const [bal, setBal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [to, setTo] = useState("");
  const [used, setUsed] = useState(0);
  const [received, setReceived] = useState(0);

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _user = await contract.userInfo(useAppState.walletAddress);
      setBal(useAppState.convert(_user.virtualUsdtBalance));
      setUsed(useAppState.convert(_user.usedVirtualUsdt));
      setReceived(useAppState.convert(_user.vUsdReceived));
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  const transfer = async () => {
    setLoading(true);
    try {
      let contract = await useAppState.getContract();
      let tx = await contract.TransferVirtualUsdt(to, ethers.parseUnits(amount, "mwei"));
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Sucessfully Transferred");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading(false);
  }

  return (
    <div className="min-h-screen w-full">
      <div class="w-full">
        <div class="h-full bg-[#1e2026] p-6 rounded-lg border-2 border-indigo-500 border-opacity-30 flex flex-col relative overflow-hidden">
          <img
            src="https://t4.ftcdn.net/jpg/04/25/76/93/360_F_425769370_6mKXcdmqw33EXCNFfYaOIIR264vUtrxq.jpg"
            alt="Team Icon"
            className="rounded-r-lg absolute left-0 top-0 md:min-w-full min-h-full opacity-50"
          />
          <span class="z-10 bg-green-500 font-bold text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
            Split Wallet
          </span>

          <h1 class="z-10 text-3xl font-bold leading-none flex justify-between items-end pb-4 mb-4 border-b border-gray-200">
            <span>${bal} + ${received}<span className='text-sm ml-4 text-red-500'>(Debt - ${used.toFixed(2)})</span></span>
          </h1>

          <label className="z-10 bg-opacity-50 md:bg-opacity-30 input input-bordered flex items-center gap-2">
            <svg className="h-5 w-5 opacity-70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><circle cx={12} cy={8} r={4} fill="currentColor"></circle><path fill="currentColor" fillOpacity={0.25} fillRule="evenodd" d="M12 13c-3.67 0-6.68 2.42-6.976 5.5c-.026.275.2.5.476.5h13a.465.465 0 0 0 .476-.5C18.68 15.42 15.67 13 12 13" clipRule="evenodd"></path></svg>
            <input value={to} onChange={(e) => setTo(e.target.value)} type="text" className="grow" placeholder="Receiver's Address" />
          </label>

          <label className="z-10 mt-4 bg-opacity-50 md:bg-opacity-30 input input-bordered flex items-center gap-2">
          <svg className="h-5 w-5 opacity-70" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="currentColor"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="white" d="M17.42 3a2 2 0 0 1 1.736 1.008L22.49 9.84a2 2 0 0 1-.322 2.406l-9.283 9.283a1.25 1.25 0 0 1-1.768 0l-9.283-9.283a2 2 0 0 1-.322-2.406l3.333-5.833A2 2 0 0 1 6.58 3zM15 6H9a1 1 0 0 0-.117 1.993L9 8h2v1.545c-.758.07-1.447.217-2.004.426c-.395.148-.749.336-1.013.571s-.483.557-.483.958s.219.724.483.958c.264.235.618.423 1.013.57c.594.223 1.338.377 2.157.44a1 1 0 0 0-.146.416L11 14v2a1 1 0 0 0 1.993.117L13 16v-2a1 1 0 0 0-.153-.532c.819-.063 1.563-.216 2.157-.44c.395-.147.749-.335 1.013-.57s.483-.557.483-.958s-.219-.724-.483-.958c-.264-.235-.618-.423-1.013-.57a7.5 7.5 0 0 0-1.683-.392L13 9.545V8h2a1 1 0 0 0 .117-1.993zm-2.001 4.55a6.8 6.8 0 0 1 1.654.357c.329.124.56.259.7.383a.46.46 0 0 1 .14.178l.007.032l-.007.032a.46.46 0 0 1-.14.178c-.14.124-.371.26-.7.382c-.655.246-1.593.408-2.653.408s-1.998-.162-2.653-.408c-.329-.123-.56-.258-.701-.382a.46.46 0 0 1-.14-.178L8.5 11.5c0-.013.005-.085.146-.21s.372-.26.701-.382c.44-.165 1.007-.293 1.654-.358a1 1 0 0 0 1.998 0"></path></g></svg>
            <input value={amount} onChange={(e) => setAmount(e.target.value)} type="text" className="grow" placeholder="Amount" />
          </label>

          {loading ?
            <button className="z-10 btn glass mt-6 w-full"><Bars color="white" height={20} /></button>
            :
            <button onClick={transfer} className="z-10 btn glass mt-6 w-full font-bold">Transfer</button>
          }
          
        </div>

        <TransferHistory />
      </div>
    </div>
  )
}

export default VirtualUsdt