import React, { useContext, useEffect, useState } from "react";
import { AppState } from "../App";
import { ethers } from "ethers";
import {Bars} from 'react-loader-spinner'
import { message } from "antd";
import WithdrawalHistory from "./WithdrawalHistory";

const Wallet = () => {
  const useAppState = useContext(AppState);
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    totalIncome: 0,
    airdropReward: 0,
    upgradingIncome: [0,0],
    directIncome: [0,0],
    levelIncome: [0,0],
    weeklyIncome: [0,0],
    monthlyIncome: [0,0],
    nonWorkingIncome: [0,0]
  })

  const [amtInDc, setAmtInDc] = useState(0);

  async function getTokenOut(_in) {
    let contract = await useAppState.getContract();
    let _out = await contract.getPriceFromUniswapV2.staticCall(ethers.parseUnits(_in.toString(), "mwei"));
    return useAppState.convert(_out);
  }

  useEffect(() => {
    async function getData() {
      let contract = await useAppState.getContract();
      let _info = await contract.getUserWalletInfo(useAppState.walletAddress);
     
      let _data = {
        totalIncome: useAppState.convert(_info[2][0]) + useAppState.convert(_info[3][0]) + useAppState.convert(_info[4][0]) + useAppState.convert(_info[5][0]) + useAppState.convert(_info[6][0]),
        airdropReward: useAppState.convert(_info[1]),
        upgradingIncome: [useAppState.convert(_info[2][0]), useAppState.convert(_info[2][1])],
        directIncome: [useAppState.convert(_info[3][0]), useAppState.convert(_info[3][1])],
        weeklyIncome: [useAppState.convert(_info[4][0]), useAppState.convert(_info[4][1])],
        monthlyIncome: [useAppState.convert(_info[5][0]), useAppState.convert(_info[5][1])],
        nonWorkingIncome: [useAppState.convert(_info[6][0]), useAppState.convert(_info[6][1])],
      }
      setData(_data);

      if(_data.totalIncome > 0) {
        setAmtInDc(await getTokenOut(_data.totalIncome))
      } else {
        setAmtInDc(0);
      }
    }
    getData();
  },[useAppState.walletAddress, useAppState.change])

  const withdraw = async () => {
    setLoading(true);
    try {
      let contract = await useAppState.getContract();
      let tx = await contract.withdraw();
      await tx.wait();
      useAppState.setChange(useAppState.change + 1);
      message.success("Withdrawal Sucessfull");
      // message.warning("Site under maintainance...");
    } catch (error) {
      message.error(error.reason);
    }
    setLoading(false);
  }

  return (
    <div className="min-h-screen w-full">
      <div class="w-full">
        <div class="h-full bg-[#1e2026] p-6 rounded-lg border-2 border-indigo-500 border-opacity-30 flex flex-col relative overflow-hidden">
          <img
            src="https://acropolium.com/img/articles/investing-in-cryptocurrency-wallet-development-cost-and-benefits/img01.jpg"
            alt="Team Icon"
            className="rounded-r-lg absolute left-0 top-0 md:min-w-full min-h-full opacity-50"
          />
          <span class="z-10 bg-green-500 font-bold text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
            Wallet
          </span>
          
          <h1 class="z-10 text-5xl font-bold leading-none flex items-end pb-4 mb-4 border-b border-gray-200">
            <span>${data.totalIncome.toFixed(2)}</span> <span className="ml-3 mb-1 text-sm">{amtInDc.toFixed(2)} FAI Tokens</span>
          </h1>

          <div className="mt-2 text-white font-semibold rounded-lg w-full flex justify-between overflow-hidden items-start bg-black bg-opacity-40 p-2 px-3 relative">
            {/* <img
              src="https://png.pngtree.com/thumb_back/fh260/background/20201022/pngtree-abstract-technology-background-line-high-tech-electricity-image_430309.jpg"
              alt="Team Icon"
              className="rounded-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
            /> */}
            <h1 className="z-10">
              <h2>Direct Income</h2>
              <h2>${data.directIncome[0].toFixed(2)}</h2>
            </h1>
            <h1 className="z-10 text-end">
              <h2>Total</h2>
              <h2>${data.directIncome[1].toFixed(2)}</h2>
            </h1>
          </div>

          <div className="mt-2 text-white font-semibold rounded-lg w-full flex justify-between overflow-hidden items-start bg-black bg-opacity-40 p-2 px-3 relative">
            {/* <img
              src="https://png.pngtree.com/thumb_back/fh260/background/20201022/pngtree-abstract-technology-background-line-high-tech-electricity-image_430309.jpg"
              alt="Team Icon"
              className="rounded-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
            /> */}
            <h1 className="z-10">
              <h2>Upgrading Income</h2>
              <h2>${data.upgradingIncome[0].toFixed(2)}</h2>
            </h1>
            <h1 className="z-10 text-end">
              <h2>Total</h2>
              <h2>${data.upgradingIncome[1].toFixed(2)}</h2>
            </h1>
          </div>

          <div className="mt-2 text-white font-semibold rounded-lg w-full flex justify-between overflow-hidden items-start bg-black bg-opacity-40 p-2 px-3 relative">
            {/* <img
              src="https://png.pngtree.com/thumb_back/fh260/background/20201022/pngtree-abstract-technology-background-line-high-tech-electricity-image_430309.jpg"
              alt="Team Icon"
              className="rounded-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
            /> */}
            <h1 className="z-10">
              <h2>Weekly Income</h2>
              <h2>${data.weeklyIncome[0].toFixed(2)}</h2>
            </h1>
            <h1 className="z-10 text-end">
              <h2>Total</h2>
              <h2>${data.weeklyIncome[1].toFixed(2)}</h2>
            </h1>
          </div>

          <div className="mt-2 text-white font-semibold rounded-lg w-full flex justify-between overflow-hidden items-start bg-black bg-opacity-40 p-2 px-3 relative">
            {/* <img
              src="https://png.pngtree.com/thumb_back/fh260/background/20201022/pngtree-abstract-technology-background-line-high-tech-electricity-image_430309.jpg"
              alt="Team Icon"
              className="rounded-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
            /> */}
            <h1 className="z-10">
              <h2>Royalty Income</h2>
              <h2>${data.monthlyIncome[0].toFixed(2)}</h2>
            </h1>
            <h1 className="z-10 text-end">
              <h2>Total</h2>
              <h2>${data.monthlyIncome[1].toFixed(2)}</h2>
            </h1>
          </div>

          <div className="mt-2 text-white font-semibold rounded-lg w-full flex justify-between overflow-hidden items-start bg-black bg-opacity-40 p-2 px-3 relative">
            {/* <img
              src="https://png.pngtree.com/thumb_back/fh260/background/20201022/pngtree-abstract-technology-background-line-high-tech-electricity-image_430309.jpg"
              alt="Team Icon"
              className="rounded-lg absolute left-0 top-0 min-w-full min-h-full opacity-50"
            /> */}
            <h1 className="z-10">
              <h2>Non-Working Global Income</h2>
              <h2>${data.nonWorkingIncome[0].toFixed(2)}</h2>
            </h1>
            <h1 className="z-10 text-end">
              <h2>Total</h2>
              <h2>${data.nonWorkingIncome[1].toFixed(2)}</h2>
            </h1>
          </div>

          {loading ?
            <button className="btn glass mt-6 w-full"><Bars color="white" height={20} /></button>
            :
            <button onClick={withdraw} className="btn glass mt-6 w-full font-bold">Withdraw</button>
          }

        </div>

        <WithdrawalHistory />
      </div>
    </div>
  );
};

export default Wallet;
