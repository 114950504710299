import React, { useContext, useState } from "react";
import { AppState } from "../App";
import Marquee from "react-fast-marquee";
import { Collapse, Typography, Card } from "antd";
import { Row, Col } from "antd";
import { AiOutlineSafety, AiOutlineThunderbolt, AiOutlineGlobal, AiOutlineRobot, AiOutlineDatabase, AiOutlineLock } from "react-icons/ai";

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;


const features = [
  {
    title: "Decentralized Governance",
    description:
      "Operates on a smart contract ensuring no central authority has control, providing true decentralization and trust.",
    icon: <AiOutlineSafety size={40} color="white" />,
  },
  {
    title: "Real-Time Automation",
    description:
      "AI-driven smart contracts execute actions instantly based on predefined conditions, reducing delays and inefficiencies.",
    icon: <AiOutlineThunderbolt size={40} color="white" />,
  },
  {
    title: "Global Accessibility",
    description:
      "Accessible to anyone with an internet connection, eliminating geographical barriers and promoting inclusivity.",
    icon: <AiOutlineGlobal size={40} color="white" />,
  },
  {
    title: "AI-Driven Smart Contracts",
    description:
      "Leveraging artificial intelligence to analyze and optimize smart contract execution for enhanced decision-making.",
    icon: <AiOutlineRobot size={40} color="white" />,
  },
  {
    title: "Secure & Transparent Data Handling",
    description:
      "All transactions are recorded on an immutable blockchain ledger, ensuring security and transparency.",
    icon: <AiOutlineDatabase size={40} color="white" />,
  },
  {
    title: "Enhanced Privacy & Security",
    description:
      "With cryptographic encryption and decentralized storage, user data remains protected from unauthorized access.",
    icon: <AiOutlineLock size={40} color="white" />,
  }
];

const faqs = [
  {
    question: "Is Fortune Ai liability free?",
    answer:
      "Yes, Fortune Ai is 100% distribution programme works on P2P model, Fortune Ai doesn’t hold community funds, it distributes reward immediately. Fortune Ai doesn’t give any ROI, it doesn’t give any fake promises. Fortune Ai is a community-building programme working on a decentralized smart contract. Fortune Ai creator leaves its ownership & makes contract renounce. Now all community members have 100% control of their accounts & unlimited earnings.",
  },
  {
    question: "How to register with Fortune Ai?",
    answer:
      "1. Connect to the Smart Contract\n- Visit the DApp (Decentralized Application) or platform hosting the smart contract.\n- Click 'Connect Wallet' and choose your wallet.\n- Approve the connection request in your wallet.\n\n2. Register on the Smart Contract\n- Look for a 'Register' or 'Join' button.\n- Enter necessary details (if required).\n- Approve the transaction (may require gas fees).\n\n3. Confirm the Transaction\n- Your wallet will prompt you to approve the transaction.\n- Check the gas fee and confirm.\n- Wait for blockchain confirmation.\n\n4. Verify Registration\n- Check your transaction on a blockchain explorer.\n- Look for a confirmation message on the platform.\n\nImportant Tips:\n- Verify Contract Address: Always double-check the smart contract address to avoid scams.",
  },
  {
    question: "How much can I earn in 9 Levels?",
    answer:
      "512 members come in the 9th level, 2 X 2 Matrix fills up left to right. On completion of 9 levels, you get 20% of their package total amounting to $1,22,88,000.",
  },
  {
    question: "How can I use Advance Fund?",
    answer:
      "You get 100% Advance amount of your package. This fund can be used in different ways: 20% of any package. Example:\n1) For your own package upgrade.\n2) For your direct referral members.\n3) For your team members' packages.",
  },
  {
    question: "When do I get a withdrawal of my earnings?",
    answer:
      "1. Locate the Withdrawal Option\n- Navigate to the withdrawal or claim rewards section.\n\n2. Approve the Transaction\n- Click 'Withdraw' or 'Claim'.\n- Your wallet will pop up with a transaction request.\n\n3. Wait for Blockchain Confirmation\n- The transaction will be processed on the blockchain.\n\n4. Check Your Wallet Balance\n- After confirmation, check your wallet to ensure the funds are received.\n- If the funds do not appear, try refreshing your wallet or adding the token manually.",
  },
  {
    question: "What’s the benefit of Decentralized smart contract?",
    answer:
      "1. Trustless Transactions\n✅ No need for intermediaries (banks, brokers, or third parties).\n✅ Transactions are executed automatically based on pre-defined conditions.\n\n2. Security & Transparency\n✅ Smart contracts are stored on a blockchain, making them tamper-proof.\n✅ All transactions are publicly verifiable on the blockchain.\n\n3. Cost Efficiency\n✅ Eliminates middlemen, reducing transaction fees.\n✅ Automates processes, saving time and resources.\n\n4. Immutability\n✅ Once deployed, smart contracts cannot be altered, preventing fraud.\n✅ Ensures permanent and reliable execution of agreements.\n\n5. Fast & Efficient Execution\n✅ Transactions process instantly once conditions are met.\n✅ No need for manual approvals, reducing delays.\n\n6. Global Accessibility\n✅ Anyone with internet access can interact with smart contracts.\n✅ No restrictions based on geography, making it truly borderless.\n\n7. Ownership & Control\n✅ Users have full control over their assets without relying on a third party.\n✅ No risk of funds being frozen or seized by authorities.\n\n8. Programmability & Automation\n✅ Can be customized for various use cases (DeFi, NFTs, DAOs, etc.).\n✅ Automates payments, lending, staking, and rewards.",
  },
];

const Homepage = () => {
  const useAppState = useContext(AppState);

  return (
    <div className="min-h-screen w-full">
      {/* Header */}
      <div class="bg-[#1e2026] sticky top-0 z-50">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <header class="flex items-center justify-between py-4 md:py-8">
            <a
              href="/"
              class="inline-flex items-center gap-2.5 text-2xl font-bold text-white md:text-3xl"
              aria-label="logo"
            >
                <img src="logo192.png" className="h-12 bg-white rounded-full p-1" alt="cforce" />
                <span className="self-center font-bold whitespace-nowrap dark:text-white">Fortune <span className="text-pink-500">AI</span> Agent</span>
            </a>

            <nav class="hidden gap-12 lg:flex">
              <a
                href="#home"
                class="text-lg font-semibold text-gray-200 transition duration-100 hover:text-indigo-500 active:text-indigo-700"
              >
                Home
              </a>
              <a
                href="#about"
                class="inline-flex items-center gap-1 text-lg font-semibold text-gray-200 transition duration-100 hover:text-indigo-500"
              >
                About
              </a>
              <a
                href="#features"
                class="text-lg font-semibold text-gray-200 transition duration-100 hover:text-indigo-500 active:text-indigo-700"
              >
                features
              </a>
              <a
                href="#faqs"
                class="text-lg font-semibold text-gray-200 transition duration-100 hover:text-indigo-500 active:text-indigo-700"
              >
                FAQs
              </a>
            </nav>

            <div class="-ml-8 hidden flex-col gap-2.5 sm:flex-row sm:justify-center lg:flex lg:justify-start">
              <a
                onClick={() => useAppState.Login()}
                class="inline-block cursor-pointer rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base"
                >
                Sign In
              </a>
            </div>

            <button
              type="button"
              class="inline-flex items-center gap-2 rounded-lg lg:hidden"
              >
              <a
                onClick={() => useAppState.Login()}
                class="inline-block cursor-pointer rounded-lg bg-indigo-500 px-8 py-3 text-center text-sm font-semibold text-white outline-none ring-indigo-300 transition duration-100 hover:bg-indigo-600 focus-visible:ring active:bg-indigo-700 md:text-base"
              >
                Sign In
              </a>
            </button>
          </header>
        </div>
      </div>

      {/* Hero Section */}
      <section id="home" class="mx-auto max-w-screen-2xl px-4 md:px-8 mt-12">
        <div class="mb-8 flex flex-wrap justify-between md:mb-16">
          <div class="mb-6 flex w-full flex-col justify-center sm:mb-12 lg:mb-0 lg:w-1/3 lg:pb-24 lg:pt-48">
            <h1 class="mb-4 text-4xl font-bold text-white sm:text-5xl md:mb-8 md:text-5xl">
              "AI-Powered Coin 
              <br />
              for the Future"
            </h1>

            <p class="max-w-md leading-relaxed text-gray-300 xl:text-lg">
            Our AI-powered coin revolutionizes digital finance, enabling smarter, faster, and more secure transactions. Experience the future of decentralized currency with cutting-edge intelligence and innovation.
            </p>

            <button onClick={() => useAppState.Login()} className="px-6 py-2 rounded-lg font-bold text-white bg-lime-500 mt-4 w-1/2">Login</button>
          </div>

          <div class="mb-12 flex w-full md:mb-16 lg:w-2/3">
            <div class="relative left-12 top-12 z-10 -ml-12 overflow-hidden rounded-lg border-[1px] border-opacity-30 border-purple-500 bg-gray-100 shadow-lg md:left-16 md:top-16 lg:ml-0">
              <img
                src="https://plus.unsplash.com/premium_photo-1683121710572-7723bd2e235d?auto=format&q=75&fit=crop&w=550&h=550"
                loading="lazy"
                alt="Photo by Kaung Htet"
                class="h-full w-full object-cover object-center"
              />
            </div>

            <div class="overflow-hidden rounded-lg bg-gray-100 border-[1px] border-opacity-30 border-purple-500 shadow-lg">
              <img
                src="https://images.unsplash.com/photo-1613843433065-819a04a47a09?auto=format&q=75&fit=crop&w=550&h=550"
                loading="lazy"
                alt="Photo by Manny Moreno"
                class="h-full w-full object-cover object-center"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center justify-between gap-8 md:flex-row">
          <div class="flex h-12 w-64 divide-x overflow-hidden rounded-lg border">
            <a
              href="#"
              class="flex w-1/3 items-center justify-center text-gray-300 transition duration-100 hover:bg-gray-100 active:bg-gray-200"
            >
              FAI
            </a>
            <a
              href="#"
              class="flex w-1/3 items-center justify-center text-gray-300 transition duration-100 hover:bg-gray-100 active:bg-gray-200"
            >
              USDT
            </a>
            <a
              href="#"
              class="flex w-1/3 items-center justify-center text-gray-300 transition duration-100 hover:bg-gray-100 active:bg-gray-200"
            >
              POL
            </a>
          </div>

          <div class="flex items-center justify-center gap-4 lg:justify-start">
            <span class="text-sm font-semibold uppercase tracking-widest text-white sm:text-base">
              Social
            </span>
            <span class="h-px w-12 bg-gray-200"></span>

            <div class="flex gap-4">
              <a
                href="#"
                target="_blank"
                class="text-white transition duration-100"
              >
                <svg
                  class="h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>

              <a
                href="#"
                target="_blank"
                class="text-white transition duration-100"
              >
                <svg
                  class="h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </a>

              <a
                href="#"
                target="_blank"
                class="text-white transition duration-100"
              >
                <svg
                  class="h-5 w-5"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M19.4168 2.4594C17.7648 0.873472 15.4785 0 12.9793 0C9.1616 0 6.81353 1.56493 5.51603 2.87767C3.91693 4.49547 3 6.64362 3 8.77138C3 11.4429 4.11746 13.4934 5.98876 14.2563C6.11439 14.3078 6.24081 14.3337 6.36475 14.3337C6.75953 14.3337 7.07233 14.0754 7.1807 13.661C7.24389 13.4233 7.39024 12.8369 7.45389 12.5823C7.59011 12.0795 7.48005 11.8377 7.18295 11.4876C6.64173 10.8472 6.38969 10.0899 6.38969 9.10438C6.38969 6.17698 8.56948 3.06578 12.6095 3.06578C15.8151 3.06578 17.8064 4.88772 17.8064 7.82052C17.8064 9.67124 17.4077 11.3852 16.6837 12.6468C16.1805 13.5235 15.2957 14.5685 13.9375 14.5685C13.3501 14.5685 12.8225 14.3272 12.4896 13.9066C12.1751 13.5089 12.0714 12.9953 12.1979 12.4599C12.3408 11.855 12.5357 11.2241 12.7243 10.6141C13.0682 9.5001 13.3933 8.44789 13.3933 7.60841C13.3933 6.17252 12.5106 5.20769 11.1969 5.20769C9.52737 5.20769 8.21941 6.90336 8.21941 9.06805C8.21941 10.1297 8.50155 10.9237 8.62929 11.2286C8.41896 12.1197 7.16899 17.4176 6.93189 18.4166C6.79478 18.9997 5.96893 23.6059 7.33586 23.9731C8.87168 24.3858 10.2445 19.8997 10.3842 19.3928C10.4975 18.9806 10.8937 17.4216 11.1365 16.4634C11.878 17.1775 13.0717 17.6603 14.2333 17.6603C16.4231 17.6603 18.3924 16.6749 19.7786 14.8858C21.1229 13.1505 21.8633 10.7318 21.8633 8.0757C21.8632 5.99923 20.9714 3.95209 19.4168 2.4594Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <div className='w-full flex justify-center mt-12 items-center'>
        <h2 className='text-2xl font-bold text-purple-500'>Powered By</h2>
      </div>
      <Marquee className='mt-14'>
        <div className='ml-28 flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#cecaca" d="m17.82 16.342l5.692-3.287A.98.98 0 0 0 24 12.21V5.635a.98.98 0 0 0-.488-.846l-5.693-3.286a.98.98 0 0 0-.977 0L11.15 4.789a.98.98 0 0 0-.489.846v11.747L6.67 19.686l-3.992-2.304v-4.61l3.992-2.304l2.633 1.52V8.896L7.158 7.658a.98.98 0 0 0-.977 0L.488 10.945a.98.98 0 0 0-.488.846v6.573a.98.98 0 0 0 .488.847l5.693 3.286a.98.98 0 0 0 .977 0l5.692-3.286a.98.98 0 0 0 .489-.846V6.618l.072-.041l3.92-2.263l3.99 2.305v4.609l-3.99 2.304l-2.63-1.517v3.092l2.14 1.236a.98.98 0 0 0 .978 0z"></path></svg>
          <h3 className='text-gray-300 font-bold text-2xl ml-3'>Polygon</h3>
        </div>
        <div className='ml-28 flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 330 512"><path fill="#cecaca" d="M82.26 292.547L0 146.302L82.26 0h164.574L330 146.302H164.518zM330 365.697l-82.369-146.244l-82.26 146.245H0L83.056 512H247.63z"></path></svg>
          <h3 className='text-gray-300 font-bold text-2xl ml-3'>Solidity</h3>
        </div>
        <div className='ml-28 flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#cecaca" d="M22.783 24H9.317l2.196-3.69a5.23 5.23 0 0 1 4.494-2.558h6.775ZM1.217 0h21.566l-3.718 6.247H1.217ZM9.76 9.763a5.73 5.73 0 0 1 4.92-2.795h4.01L8.498 24h-7.26Z"></path></svg>
          <h3 className='text-gray-300 font-bold text-2xl ml-3'>Openzeppelin</h3>
        </div>
        <div className='ml-28 flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 1664 1664"><path fill="#cecaca" d="M1456 1216q0-40-28-68l-208-208q-28-28-68-28q-42 0-72 32q3 3 19 18.5t21.5 21.5t15 19t13 25.5t3.5 27.5q0 40-28 68t-68 28q-15 0-27.5-3.5t-25.5-13t-19-15t-21.5-21.5t-18.5-19q-33 31-33 73q0 40 28 68l206 207q27 27 68 27q40 0 68-26l147-146q28-28 28-67M753 511q0-40-28-68L519 236q-28-28-68-28q-39 0-68 27L236 381q-28 28-28 67q0 40 28 68l208 208q27 27 68 27q42 0 72-31q-3-3-19-18.5T543.5 680t-15-19t-13-25.5T512 608q0-40 28-68t68-28q15 0 27.5 3.5t25.5 13t19 15t21.5 21.5t18.5 19q33-31 33-73m895 705q0 120-85 203l-147 146q-83 83-203 83q-121 0-204-85l-206-207q-83-83-83-203q0-123 88-209l-88-88q-86 88-208 88q-120 0-204-84L100 652q-84-84-84-204t85-203L248 99q83-83 203-83q121 0 204 85l206 207q83 83 83 203q0 123-88 209l88 88q86-88 208-88q120 0 204 84l208 208q84 84 84 204"></path></svg>
          <h3 className='text-gray-300 font-bold text-2xl ml-3'>PolygonScan</h3>
        </div>
        <div className='ml-28 flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 24 24"><path fill="#cecaca" d="M20.083 6.972L19.5 5.056l-.583 1.916L17 7.556l1.917.583l.583 1.917l.583-1.917L22 7.556zM5.89 7.695c0-.228.172-.417.389-.417h3.11c.218 0 .39.189.39.417s-.172.416-.39.416h-3.11c-.217 0-.39-.189-.39-.416m7.222 0c0-.228.172-.417.389-.417h3.111c.217 0 .39.189.39.417s-.173.416-.39.416H13.5c-.217 0-.389-.189-.389-.416M7 9.767c0-.456.372-.822.833-.822s.834.366.834.822v2.8a.833.833 0 0 1-1.667 0zm7.222 0c0-.456.373-.822.834-.822s.833.366.833.822v2.8a.832.832 0 0 1-1.667 0zm-8.889 3.066a1.666 1.666 0 1 1-3.333 0a1.666 1.666 0 0 1 3.333 0m13.89 1.667a1.666 1.666 0 1 0 0-3.332a1.666 1.666 0 0 0 0 3.332m-4 2.411c.166.228.144.572-.056.761c-.5.467-1.867 1.273-3.823 1.273c-1.944 0-3.21-.806-3.65-1.306a.59.59 0 0 1 0-.761a.446.446 0 0 1 .678 0c.234.267 1.234.989 2.972.989c1.74 0 2.89-.723 3.2-1.017a.446.446 0 0 1 .678.061"></path></svg>
          <h3 className='text-gray-300 font-bold text-2xl ml-3'>PinkLock</h3>
        </div>
        <div className='ml-28 flex items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" viewBox="0 0 32 32"><path fill="#cecaca" d="m15.927 23.959l-9.823-5.797l9.817 13.839l9.828-13.839l-9.828 5.797zM16.073 0L6.254 16.297l9.819 5.807l9.823-5.801z"></path></svg>
          <h3 className='text-gray-300 font-bold text-2xl ml-3'>Ethereum</h3>
        </div>
      </Marquee>

      {/* About US */}
      <div id="about" class="py-6 sm:py-8 lg:py-12 mt-6">
        <div class="mx-auto max-w-screen-md px-4 md:px-8">
          <h1 class="mb-4 text-center text-2xl font-bold text-purple-500 sm:text-3xl md:mb-6">Our competitive advantage</h1>

          <p class="mb-6 text-gray-300 sm:text-lg md:mb-8">
            An AI agent in cryptocurrency is an autonomous software program that uses artificial intelligence to analyze market trends, execute trades, manage risks, detect fraud, and optimize investment strategies. These agents leverage machine learning, deep learning, and natural language processing to operate in the dynamic crypto ecosystem.<br /><br />
          </p>

          <h2 class="mb-2 text-xl font-semibold text-pink-500 sm:text-2xl md:mb-4">Key Functions of AI Agents in Crypto</h2>

          <ul class="mb-6 list-inside list-disc text-gray-300 sm:text-lg md:mb-8">
            <li>Automated Trading Bots</li>
            <li className="mt-2">AI agents analyze market data, identify trading opportunities, and execute trades automatically. They use algorithms such as arbitrage, scalping, and trend-following to maximize profits. Examples: CryptoHopper, 3Commas, Bitsgap. Market Prediction & Sentiment Analysis</li>
            <li className="mt-2">AI scans news, social media, and historical price data to predict market trends. It identifies bullish and bearish sentiments to adjust trading strategies. Risk Management & Portfolio Optimization</li>
            <li className="mt-2">AI-based risk assessment tools help diversify investments and manage risks. Smart rebalancing of portfolios based on market conditions. Fraud Detection & Security</li>
            <li className="mt-2">AI detects suspicious transactions, preventing scams and fraud in exchanges. Used in KYC/AML (Know Your Customer & Anti-Money Laundering) compliance. Smart Contract Auditing</li>
            <li className="mt-2">AI inspects blockchain smart contracts for vulnerabilities. Reduces risks of hacks and exploits in DeFi projects. Decentralized Finance (DeFi) Optimization</li>
            <li className="mt-2">AI optimizes yield farming and staking strategies. Identifies the best liquidity pools for higher returns. Blockchain Analytics & Compliance</li>
            <li className="mt-2">AI agents analyze blockchain transactions for insights and regulatory compliance. Used by governments, exchanges, and forensic firms.</li>
          </ul>

          <blockquote class="mb-6 border-l-4 pl-4 italic text-gray-300 sm:text-lg md:mb-8 md:pl-6">“Benefits of AI Agents in Crypto”</blockquote>
          <ul className="list-none">
          <li>✅  Faster decision-making</li>
          <li className="mt-2">✅  24/7 market monitoring</li>
          <li className="mt-2">✅  Reduced human error</li>
          <li className="mt-2">✅  Improved security and fraud detection</li>
          <li className="mt-2">✅  FData-driven investment strategies</li>
          </ul>

          <div class="mt-6 relative mb-6 overflow-hidden rounded-lg bg-gray-100 shadow-lg md:mb-8">
            <img src="https://media.istockphoto.com/id/1479221169/photo/businessman-touching-the-brain-working-of-artificial-intelligence-automation-predictive.jpg?s=612x612&w=0&k=20&c=4ZsJV7goEIocEmY9aHxsW8aPlnfQnQxccPVyS3bDMco=" loading="lazy" alt="Photo by Minh Pham" class="h-full w-full object-cover object-center" />
          </div>
        </div>
      </div>

      {/* Joining Bonus */}
      <div class="mt-4 py-6 sm:py-8 lg:py-12">
        <div class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="flex flex-col overflow-hidden rounded-lg bg-gray-900 sm:flex-row md:h-80">
            <div class="flex w-full flex-col p-4 sm:w-1/2 sm:p-8 lg:w-2/5">
              <h2 class="mb-4 text-xl font-bold text-white md:text-2xl lg:text-4xl">Get 100 FAI Tokens<br />Airdrop on Registeration.</h2>

              <p class="mb-8 max-w-md text-gray-400">Register on Fortune Ride and claim 100 FAI tokens as an airdrop! Join us in revolutionizing the AI-powered crypto market and shaping the future of intelligent digital currency. Don’t miss out—be part of the next financial evolution today!</p>

            </div>

            <div class="order-first h-48 w-full bg-gray-700 sm:order-none sm:h-auto sm:w-1/2 lg:w-3/5">
              <img src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" loading="lazy" alt="Photo by Dom Hill" class="h-full w-full object-cover object-center" />
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <div style={{ background: "#1e2026", padding: "60px 20px" }}>
      <Title level={2} style={{ textAlign: "center", color: "#ff4d4f" }}>
        Key Features of Our Decentralized AI Agent
      </Title>
      <Row gutter={[32, 32]} justify="center mt-4">
        {features.map((feature, index) => (
          <Col xs={24} sm={12} md={8} key={index}>
            <Card style={{ background: "#2a2d36", color: "white", textAlign: "center", borderRadius: "10px" }}>
              <div className="flex justify-center w-full" style={{ marginBottom: "20px" }}>{feature.icon}</div>
              <Title level={4} style={{ color: "green" }}>{feature.title}</Title>
              <Paragraph style={{ color: "white" }}>{feature.description}</Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </div>

      {/* FAQs */}
      <Card style={{ maxWidth: "800px", margin: "auto", border: "none", padding: "20px", background: "none", color: "white" }}>
      <Title level={2} style={{ textAlign: "center", color: "greenyellow" }}>
        Frequently Asked Questions
      </Title>
      <Collapse style={{background: "none", border: "none"}} accordion>
        {faqs.map((faq, index) => (
          <Panel showArrow={false} style={{background: "#1e2026", borderRadius: 0, color: "white"}} header={<b style={{ color: "white" }}>{faq.question}</b>} key={index}>
            <Paragraph className="text-gray-800" style={{ background: "none" }}>
              {faq.answer.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </Paragraph>
          </Panel>
        ))}
      </Collapse>
    </Card>

      {/* Footer */}
        <div class="bg-[#1e2026] pt-4 sm:pt-10 lg:pt-12 mt-14">
        <footer class="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div class="mb-16 grid grid-cols-2 gap-12 border-t pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
            <div class="col-span-full lg:col-span-2">
              <div class="mb-4 lg:-mt-2">
                <a href="/" class="inline-flex items-center gap-2 text-xl font-bold text-white md:text-2xl" aria-label="logo">
                  <img src="logo.png" className="h-14 w-14 bg-white p-2 rounded-full" />

                  Fortune AI Agent
                </a>
              </div>

              <p class="mb-6 text-gray-200 sm:pr-8">Join the AI Revolution with Fortune AI Agent.</p>

              <div class="flex gap-4">
                <a href="#" target="_blank" class="text-white transition duration-100">
                  <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </a>

                <a href="#" target="_blank" class="text-white transition duration-100">
                  <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>

                <a href="#" target="_blank" class="text-white transition duration-100">
                  <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>

                <a href="#" target="_blank" class="text-white transition duration-100">
                  <svg class="h-5 w-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
                  </svg>
                </a>
              </div>
            </div>

          </div>

          <div class="border-t py-8 text-center text-sm text-white">© 2025 - Present Fortuen AI Agent. All rights reserved.</div>
        </footer>
      </div>

    </div>
  );
};

export default Homepage;
