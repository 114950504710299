import { useState, useEffect, useContext } from "react"
import { Link, useLocation } from "react-router-dom";
import { Button, Popover } from 'antd';
import { AppState } from "../App";
import Register from "./Register";

const Layout = ({Component}) => {
   const location = useLocation();
    const useAppState = useContext(AppState);
    const [showSidebar, setShowSidebar] = useState(false);
    const [isMd, setIsMd] = useState(false);

    const IDContent = (
        <div>
          <p className="bg-gray-200 rounded-md border-gray-300 border-opacity-50 text-gray-900 border-2 p-1 font-semibold">{useAppState.walletAddress.slice(0, 12)}...{useAppState.walletAddress.slice(36)}</p>
          <p className="mt-2 flex items-center bg-gray-200 rounded-md border-gray-300 border-opacity-50 text-gray-900 border-2 p-1 font-semibold">
            <svg className='mr-3' xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 128 128"><path fill="#fff" d="M64 0c35.348 0 64 28.652 64 64s-28.652 64-64 64S0 99.348 0 64S28.652 0 64 0m0 0"/><path fill="#7950dd" d="M85.898 49.242a5.76 5.76 0 0 0-5.418 0l-12.214 7.223l-8.532 4.742l-12.214 7.227a5.76 5.76 0 0 1-5.418 0l-9.707-5.649a5.42 5.42 0 0 1-2.711-4.52V46.989a4.97 4.97 0 0 1 2.71-4.52l9.708-5.417a5.74 5.74 0 0 1 5.418 0l9.707 5.418a5.42 5.42 0 0 1 2.71 4.52v7.218l8.329-4.965v-6.996a4.96 4.96 0 0 0-2.664-4.52l-17.86-10.382a5.74 5.74 0 0 0-5.418 0L24.266 37.727a4.61 4.61 0 0 0-2.934 4.52v20.991a4.97 4.97 0 0 0 2.711 4.496l18.059 10.407a5.76 5.76 0 0 0 5.418 0l12.214-7l8.352-4.965l12.172-6.977a5.76 5.76 0 0 1 5.418 0l9.707 5.418a5.42 5.42 0 0 1 2.707 4.52v11.062a4.97 4.97 0 0 1-2.707 4.516l-9.707 5.64a5.74 5.74 0 0 1-5.418 0l-9.707-5.418a5.42 5.42 0 0 1-2.711-4.515v-7.25l-8.106 4.738v7.219a4.97 4.97 0 0 0 2.707 4.52L80.5 100.03a5.75 5.75 0 0 0 5.422 0l18.058-10.383a5.42 5.42 0 0 0 2.688-4.511v-21a4.96 4.96 0 0 0-2.711-4.516zm0 0"/></svg>
            {useAppState.bal.toFixed(4)} POL
          </p>
          <p className="mt-2 flex items-center bg-gray-200 rounded-md border-gray-300 border-opacity-50 text-gray-900 border-2 p-1 font-semibold">
          <svg className="mr-3" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 32 32"><g fill="none" fillRule="evenodd"><circle cx={16} cy={16} r={16} fill="#26a17b"></circle><path fill="#fff" d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042c-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658s2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061c1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658c0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118s3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116s-3.301-1.914-7.694-2.117"></path></g></svg>
            {useAppState.bal2.toFixed(2)} USDT
          </p>
          <p className="mt-2 flex items-center bg-gray-200 rounded-md border-gray-300 border-opacity-50 text-gray-900 border-2 p-1 font-semibold">
            <img src='logo.png' className='h-6 w-6 bg-white p-1 rounded-full mr-3' />
            {useAppState.bal3.toFixed(2)} FAI
          </p>
        </div>
      );

    useEffect(() => {
        const isMdScreen = window.matchMedia("(min-width: 768px)").matches;
        if (isMdScreen) {
          setShowSidebar(true);
          setIsMd(true);
        }
      },[])

  return (
    <>
        <Register />
        <nav className="fixed top-0 z-50 w-full border-b border-gray-500 bg-[#1e2026]">
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start rtl:justify-end">
                <button onClick={() => setShowSidebar((!showSidebar))} data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                       <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                 </button>
                <Link to={'/'}><a className="flex ms-2 md:me-24">
                  <img src="logo192.png" className="h-8 me-3 bg-white rounded-full p-1" alt="FlowBite Logo" />
                  <span className="self-center text-xl font-bold sm:text-2xl whitespace-nowrap dark:text-white">Fortune <span className="text-pink-500">AI</span> Agent</span>
                </a></Link>
              </div>
              <div className="flex items-center">
                  <div className="flex items-center ms-3">
                    <div>
                        <Popover placement="leftTop" content={IDContent} title="ID Info" trigger="hover">
                            <button type="button" className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" aria-expanded="false" data-dropdown-toggle="dropdown-user">
                              <span className="sr-only">Open user menu</span>
                              <img className="w-8 h-8 rounded-full" src="https://flowbite.com/docs/images/people/profile-picture-5.jpg" alt="user photo" />
                            </button>
                        </Popover>
                    </div>
                    {/* User Information */}
                  </div>
                </div>
            </div>
          </div>
        </nav>

        <div className="flex w-full">
            <aside id="logo-sidebar" 
            className={`fixed top-0 left-0 z-40 w-64 h-screen bg-[#1e2026] pt-20 transition-transform ${showSidebar ? 'translate-x-0' : '-translate-x-full'} border-r border-gray-500`} aria-label="Sidebar">
               <div className="h-full px-3 pb-4 overflow-y-auto">
                  <ul className="space-y-2 font-medium">
                     <li>
                        <Link to={'/dashboard'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/dashboard" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 22 21">
                              <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                              <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                           </svg>
                           <span className="ms-3">Dashboard</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/teams'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/teams" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 18">
                              <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                           </svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Teams</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/community'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/community" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 18">
                           <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M4.882 1.731a.48.48 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.7.7 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a1 1 0 0 0-.462-.04 7 7 0 0 1 2.45-2.027m-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.8.8 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.45.45 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282l.04-.001a7.003 7.003 0 0 1-12.658.905Z"/>
                           </svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Community</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/tree'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/tree" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 20 18">
                           <path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H11a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 5 7h2.5V6A1.5 1.5 0 0 1 6 4.5zm-3 8A1.5 1.5 0 0 1 4.5 10h1A1.5 1.5 0 0 1 7 11.5v1A1.5 1.5 0 0 1 5.5 14h-1A1.5 1.5 0 0 1 3 12.5zm6 0a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1A1.5 1.5 0 0 1 9 12.5z"/>
                           </svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Tree</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/virtualusdt'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/virtualusdt" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="none"><path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path><path fill="white" d="M17.42 3a2 2 0 0 1 1.736 1.008L22.49 9.84a2 2 0 0 1-.322 2.406l-9.283 9.283a1.25 1.25 0 0 1-1.768 0l-9.283-9.283a2 2 0 0 1-.322-2.406l3.333-5.833A2 2 0 0 1 6.58 3zM15 6H9a1 1 0 0 0-.117 1.993L9 8h2v1.545c-.758.07-1.447.217-2.004.426c-.395.148-.749.336-1.013.571s-.483.557-.483.958s.219.724.483.958c.264.235.618.423 1.013.57c.594.223 1.338.377 2.157.44a1 1 0 0 0-.146.416L11 14v2a1 1 0 0 0 1.993.117L13 16v-2a1 1 0 0 0-.153-.532c.819-.063 1.563-.216 2.157-.44c.395-.147.749-.335 1.013-.57s.483-.557.483-.958s-.219-.724-.483-.958c-.264-.235-.618-.423-1.013-.57a7.5 7.5 0 0 0-1.683-.392L13 9.545V8h2a1 1 0 0 0 .117-1.993zm-2.001 4.55a6.8 6.8 0 0 1 1.654.357c.329.124.56.259.7.383a.46.46 0 0 1 .14.178l.007.032l-.007.032a.46.46 0 0 1-.14.178c-.14.124-.371.26-.7.382c-.655.246-1.593.408-2.653.408s-1.998-.162-2.653-.408c-.329-.123-.56-.258-.701-.382a.46.46 0 0 1-.14-.178L8.5 11.5c0-.013.005-.085.146-.21s.372-.26.701-.382c.44-.165 1.007-.293 1.654-.358a1 1 0 0 0 1.998 0"></path></g></svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Virtual USDT</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/wallet'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/wallet" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="white" fillRule="evenodd" clipRule="evenodd"><path d="M21.1 8.004q-.085-.005-.181-.004h-2.525c-2.068 0-3.837 1.628-3.837 3.75s1.77 3.75 3.837 3.75h2.525q.096.001.182-.004a1.755 1.755 0 0 0 1.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 0 0-1.645-1.628m-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1"></path><path d="M20.918 17a.22.22 0 0 1 .221.278c-.2.712-.519 1.32-1.03 1.83c-.749.75-1.698 1.081-2.87 1.239c-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87C2 15.099 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153c1.172.158 2.121.49 2.87 1.238c.511.512.83 1.119 1.03 1.831a.22.22 0 0 1-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25zM5.75 7a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5z"></path></g></svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Wallet</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/manage'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/manage" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="white" fillRule="evenodd" clipRule="evenodd"><path d="M21.1 8.004q-.085-.005-.181-.004h-2.525c-2.068 0-3.837 1.628-3.837 3.75s1.77 3.75 3.837 3.75h2.525q.096.001.182-.004a1.755 1.755 0 0 0 1.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 0 0-1.645-1.628m-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1"></path><path d="M20.918 17a.22.22 0 0 1 .221.278c-.2.712-.519 1.32-1.03 1.83c-.749.75-1.698 1.081-2.87 1.239c-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87C2 15.099 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87c.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153c1.172.158 2.121.49 2.87 1.238c.511.512.83 1.119 1.03 1.831a.22.22 0 0 1-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25zM5.75 7a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5z"></path></g></svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Manage Teams</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/incomehistory'}><a onClick={() => setShowSidebar(isMd ? true : false)} className={`${location.pathname == "/incomehistory" ? "bg-gray-700" : null} flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group`}>
                           <svg className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="white" d="M13.26 3C8.17 2.86 4 6.95 4 12H2.21c-.45 0-.67.54-.35.85l2.79 2.8c.2.2.51.2.71 0l2.79-2.8a.5.5 0 0 0-.36-.85H6c0-3.9 3.18-7.05 7.1-7c3.72.05 6.85 3.18 6.9 6.9c.05 3.91-3.1 7.1-7 7.1c-1.61 0-3.1-.55-4.28-1.48a.994.994 0 0 0-1.32.08c-.42.42-.39 1.13.08 1.49A8.86 8.86 0 0 0 13 21c5.05 0 9.14-4.17 9-9.26c-.13-4.69-4.05-8.61-8.74-8.74m-.51 5c-.41 0-.75.34-.75.75v3.68c0 .35.19.68.49.86l3.12 1.85c.36.21.82.09 1.03-.26c.21-.36.09-.82-.26-1.03l-2.88-1.71v-3.4c0-.4-.34-.74-.75-.74"></path></svg>
                           <span className="flex-1 ms-3 whitespace-nowrap">Income History</span>
                        </a></Link>
                     </li>
                     <li>
                        <Link to={'/'}>
                            <a className="flex mt-6 justify-center items-center p-2 text-white rounded-lg bg-red-500 hover:bg-red-600 group">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="white" d="M5 3h6a3 3 0 0 1 3 3v4h-1V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-4h1v4a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3m3 9h11.25L16 8.75l.66-.75l4.5 4.5l-4.5 4.5l-.66-.75L19.25 13H8z"></path></svg>
                               <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
                            </a>
                        </Link>
                     </li>
                  </ul>
               </div>
               {/* <span className="fixed bottom-5 text-center w-full text-pink-500">BCWorld 2024 v1</span> */}
            </aside>

            <div className="p-6 sm:ml-64 w-full md:w-4/5 mt-16">
               <div className="w-full">
                    <Component />
               </div>
            </div>

        </div>
    </>
  )
}

export default Layout