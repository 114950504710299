import { useContext, useEffect, useState, useRef } from "react"
import { AppState } from "../App"
import { message } from "antd"
import { TailSpin } from "react-loader-spinner"

const NonWorkingPool = () => {
  const useAppState = useContext(AppState)
  const intervalRef = useRef()
  const [claimable, setClaimable] = useState(0)
  const [lost, setLost] = useState(0)
  const [totalLost, setTotalLost] = useState(0);
  const [time, setTime] = useState(0)
  const [lastClaimed, setLastClaimed] = useState(0)
  const [loading, setLoading] = useState(false);

  const directRequiredForNonWorkingPool = 2
  const [directTeam, setDirectTeam] = useState(0)

  const maximumRewardDays = 700
  const [claimedRewardDays, setClaimedRewardDays] = useState(0)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (Number(lastClaimed) > 0) {
        setTime(Date.now() - Number(lastClaimed))
      } else {
        setTime(0)
      }
    }, 10)
    return () => clearInterval(intervalRef.current)
  }, [lastClaimed])

  useEffect(() => {
    async function getData() {
      const contract = await useAppState.getContract()
      const _nonWorkingData = await contract.claimNonWorkingPool.staticCall(useAppState.walletAddress)
      setClaimable(useAppState.convert(_nonWorkingData[0]))
      setLost(useAppState.convert(_nonWorkingData[1]))

      const _lastClaimed = await contract.nonWorkingLastClaimed(useAppState.walletAddress)
      setLastClaimed(Number(_lastClaimed) * 1000)

      const _claimedDays = await contract.nonWorkingPoolTotalClaimed(useAppState.walletAddress)
      setClaimedRewardDays(Number(_claimedDays))

      const _userInfo = await contract.userInfo(useAppState.walletAddress)
      setDirectTeam(Number(_userInfo.directTeam))

      const _totalLost = await contract.totalNonWorkingLost(useAppState.walletAddress);
      setTotalLost(useAppState.convert(_totalLost));
    }
    getData()
  }, [useAppState.change, useAppState.walletAddress])

  const handleClaim = async () => {
    setLoading(true);
    try {
        let contract = await useAppState.getContract();
        let tx = await contract.claimNonWorkingPool(useAppState.walletAddress);
        await tx.wait();
        useAppState.setChange(useAppState.change + 1)
        message.success("Sucessfully Claimed");
    } catch (error) {
        message.error(error.reason)
    }
    setLoading(false);
  }

  const directTeamProgress = (directTeam / directRequiredForNonWorkingPool) * 100
  const daysProgress = (claimedRewardDays / maximumRewardDays) * 100

  return (
    <div className="w-full mt-6 overflow-hidden bg-gradient-to-br from-indigo-200 to-purple-300 rounded-xl border border-indigo-100/50 shadow-lg relative">
      {/* Decorative element */}
      <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-purple-400/20 to-indigo-500/20 rounded-bl-full"></div>

      {/* Header */}
      <div className="p-5 pb-2">
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-indigo-600 bg-clip-text text-transparent">
          Non-Working Global Pool
        </h2>
        <p className="text-sm text-gray-500">Manage your rewards and track progress</p>
      </div>

      {/* Content */}
      <div className="p-5 space-y-6">
        {/* Rewards Section */}
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-white/80 rounded-xl p-4 shadow-sm border border-indigo-100">
            <div className="flex items-center gap-2 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-green-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="8" r="7"></circle>
                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
              </svg>
              <span className="text-sm font-medium text-gray-600">Claimable Reward</span>
            </div>
            <div className="text-xl font-bold text-green-600">${claimable.toFixed(2)} <span className="text-red-500 font-medium text-sm">(${lost.toFixed(2)} Lost)</span></div>
          </div>

          <div className="bg-white/80 rounded-xl p-4 shadow-sm border border-indigo-100">
            <div className="flex items-center gap-2 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-amber-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                <line x1="12" y1="9" x2="12" y2="13"></line>
                <line x1="12" y1="17" x2="12.01" y2="17"></line>
              </svg>
              <span className="text-sm font-medium text-gray-600">Lost Reward</span>
            </div>
            <div className="text-xl font-bold text-amber-600">${totalLost.toFixed(2)}</div>
          </div>
        </div>

        {/* Timer Section */}
        <div className="bg-white/80 rounded-xl p-4 shadow-sm border border-indigo-100">
          <div className="flex items-center gap-2 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 text-indigo-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
            <span className="text-sm font-medium text-gray-600">Next Claim Available In</span>
          </div>
          <div className="text-xl font-mono font-bold text-indigo-600">
            {/* Timer placeholder - user will implement */}
            <div id="timer-placeholder" className="h-8 flex items-center">
              {useAppState.formatTime(time)}
            </div>
          </div>
        </div>

        {/* Progress Bars */}
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <span className="text-sm font-medium text-gray-600">Reward Days Progress</span>
              <span className="text-sm font-medium text-indigo-600">
                {claimedRewardDays} / {maximumRewardDays} Days
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div
                className="bg-indigo-600 h-2 rounded-full transition-all duration-300"
                style={{ width: `${daysProgress > 100 ? 100 : daysProgress}%` }}
              ></div>
            </div>
          </div>

          <div className="space-y-2">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-purple-500"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
                <span className="text-sm font-medium text-gray-600">Direct Team Bonus</span>
              </div>
              <span className="text-sm font-medium text-purple-600">
                {directTeam} / {directRequiredForNonWorkingPool}
              </span>
            </div>
            <div className="w-full bg-purple-100 rounded-full h-2">
              <div
                className="bg-gradient-to-r from-purple-500 to-indigo-500 h-2 rounded-full transition-all duration-300"
                style={{ width: `${directTeamProgress > 100 ? 100 : directTeamProgress}%` }}
              ></div>
            </div>
            {directTeam >= directRequiredForNonWorkingPool && (
              <div className="text-xs text-green-600 font-medium">Bonus unlocked! Extra rewards available.</div>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="p-5 pt-2">
        <button
          onClick={handleClaim}
          className={`w-full py-3 px-4 rounded-lg font-medium text-white transition-all duration-200 ${
            claimable <= 0
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-gradient-to-r flex justify-center items-center from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700"
          }`}
          disabled={claimable <= 0}
        >
            {loading ? <TailSpin height={20} color="white" />
            : "Claim Rewards"
            }
        </button>
      </div>
    </div>
  )
}

export default NonWorkingPool

